angular.module('app.validadorService', [

])

.factory('validadorService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {

    return {
      validar: function( data ) {
        var deferred = $q.defer();
        $http.post( appSettings.restService + 'codigo/validar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      list: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restService + 'validador/lista' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      add: function( data ) {
        var deferred = $q.defer();
        $http.post( appSettings.restService + 'validador/agregar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },

      get: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restService + 'validador/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getRrhh: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restRrhh + 'catalogo/empleado/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      update: function( data ) {
        var deferred = $q.defer();
        $http.put( appSettings.restService + 'validador/' + data.validadorId, data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      delete: function( id ) {
        var deferred = $q.defer();
        $http.delete( appSettings.restService + 'validador/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
  }

}]);
