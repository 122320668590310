angular.module('app.genericoService', [
  
])

.factory('genericoService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {

  return {
    
  }
                                                                                                   
}]);