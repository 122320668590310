var myApp=angular.module('app.tablero', [
  'ui.router',
  'toastr',
  'app.agendaService',
  'app.authService'
])
.config(
  ['$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.tablero', {
          abstract: true,
          url: 'tablero',
          params: {
          },
          views: {
            '': {
              templateUrl: 'app/tablero/tablero.tpl.html',
              resolve: {
              },
              controller: ['$scope','$window', '$state', 'toastr',
                function (  $scope, $window,  $state,   toastr) {

                }
              ]
            }
          }
        }
      )
      .state( 'index.tablero.list', {
        url: '/:sesionId/list',
        views: {
          '': {
            templateUrl: 'app/tablero/tablero.agenda.tpl.html',
            resolve: {
              permisoEstado : ['authService',
                function( authService){
                  return authService.loginPermission(3);
                }],
              dataTipoVoto: ['agendaService', '$stateParams',
                function( agendaService, $stateParams){
                  return agendaService.tiposVoto();
                }]
            },
            controller: ['$scope','$rootScope', '$state', 'toastr', 'agendaService','$filter','dataTipoVoto', '$interval','localStorageService',
              function (  $scope, $rootScope,  $state,   toastr,   agendaService, $filter,  dataTipoVoto, $interval,localStorageService) {
                $scope.dataForm = {};
                $scope.emitidos = 0;
                $scope.dataDetalle=JSON.parse(localStorageService.get('detalles'));
                $scope.totalVotos = parseInt($scope.dataParametro.valor);
                $scope.labels = ["Si", "No"];
                $scope.data = [$scope.emitidos, $scope.totalVotos];
                $scope.labels2 = ["Tipo 1", "Tipo 2", "Tipo 3", "Tipo 4", "Tipo 5"];
                $scope.data2 = [0, 0, 0, 0, 0];
                if (dataTipoVoto.data.length > 0) {
                  var tmpData = [];
                  var tmpTipo = [];
                  var tmpData2 = [];
                  for (var i = 0; i < dataTipoVoto.data.length; i++) {
                    tmpTipo.push(dataTipoVoto.data[i].descripcion);
                    tmpData2.push(0);
                    var item = {
                      tipoVotoId : dataTipoVoto.data[i].tipoVotoId,
                      tipoVotoDesc : dataTipoVoto.data[i].descripcion,
                      votos : 0
                    };
                    tmpData.push(item);
                  }
                  var item = {
                    tipoVotoId : 0,
                    tipoVotoDesc : '',
                    votos : 0
                  };
                  tmpData.push(item);
                  $scope.labels2 = tmpTipo;
                  $scope.data2 = tmpData2;
                  $scope.dataTipoVoto = tmpData;
                }

                $scope.actualizarInfo = function ( detalleSesionId ) {
                  agendaService.getVotoDetalle( detalleSesionId ).then( function ( result ) {
                    if(result.status=='OK') {
                      var data = result.data;
                      $scope.emitidos = result.data.length;
                      $scope.noEmitidos = parseInt($scope.totalVotos) - parseInt($scope.emitidos);
                      $scope.data = [$scope.emitidos, $scope.noEmitidos];
                      var tmpData = $scope.dataTipoVoto;
                      //limpiar primero
                      var j = 0;
                      for (j = 0; j < tmpData.length; j++) {
                        tmpData[j].votos = 0;
                      }
                      for (var i = 0; i < data.length; i++) {
                        for (j = 0; j < tmpData.length; j++) {
                          if (tmpData[j].tipoVotoId == data[i].tcTipoVoto.tipoVotoId) {
                            tmpData[j].votos = parseInt(tmpData[j].votos) + 1;
                            break;
                          }
                        }
                      }
                      var tmpTipo = [];
                      var tmpData2 = [];
                      for (var i = 0; i < tmpData.length; i++) {
                        tmpTipo.push(tmpData[i].tipoVotoDesc);
                        tmpData2.push(tmpData[i].votos);
                      }
                      $scope.labels2 = tmpTipo;
                      $scope.data2 = tmpData2;
                      $scope.dataVoto = data;
                    } else {
                      $scope.emitidos = 0;
                      $scope.data = [$scope.emitidos, $scope.totalVotos];
                      var tmpData = $scope.dataTipoVoto;
                      var tmpTipo = [];
                      var tmpData2 = [];
                      for (var i = 0; i < tmpData.length; i++) {
                        tmpTipo.push(tmpData[i].tipoVotoDesc);
                        tmpData2.push(0);
                      }
                      $scope.labels2 = tmpTipo;
                      $scope.data2 = tmpData2;
                      $scope.dataVoto = [];
                      toastr.error( result.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }

                $scope.obtenerVotos = function ( detalleSesionId ) {
                  $scope.actualizarInfo( detalleSesionId );
                }

                $interval( function() {
                  if ($scope.dataForm.detalleSesionId != null) {
                    var detalleSesionId = $scope.dataForm.detalleSesionId;
                    $scope.actualizarInfo( detalleSesionId );
                  }
                }, 60000);

              } //end
            ]
          }
        }
      })
    }
  ]
);
