angular.module('app.usuarioService', [

])

.factory('usuarioService', ['$http', '$q', 'appSettings','authService', function ( $http, $q, appSettings , authService) {

  return {
      list: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'contacto/lista' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      add: function( data ) {
        var deferred = $q.defer();
        data.usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.post( appSettings.restApiServiceBaseUri + 'contacto/agregar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },

      get: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'contacto/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      update: function( data ) {
        var deferred = $q.defer();
        data.usuarioModificaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.put( appSettings.restApiServiceBaseUri + 'contacto/' + data.contactoId, data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      delete: function( id ) {
        var deferred = $q.defer();
        data.usuarioModificaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.delete( appSettings.restApiServiceBaseUri + 'catalogo/prueba/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      users: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restActiveDirectory + 'usuario/lista' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      }

  }

}]);
