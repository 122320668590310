angular.module('app.validador', [
  'ui.router',
  'toastr',
  'app.validadorService'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.validador', {

          abstract: true,

          url: 'validador',

          params: {

          },

          views: {
            '': {
              templateUrl: 'app/validador/validador.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(1);
                  }]
              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {

                }]
            }
          }

        })
        .state( 'index.validador.add', {
          url: '/add',
          views: {
            '': {
              templateUrl: 'app/validador/validador.add.tpl.html',
              resolve: {
              },
              controller: ['$scope', '$state', 'toastr', 'validadorService', 'focus',
                function (  $scope,   $state,   toastr,   validadorService, focus) {
                  $scope.data = {
                    correlativo : 0,
                    codigo : 0,
                    general : null,
                    estadoId : 0
                  };
                  $scope.submitForm = function ( isValid ) {
                    if ( isValid ) {
                      var strCodigo = '' + $scope.data.general + '';
                      strCodigo = strCodigo.trim();
                      $scope.data.correlativo = parseInt(strCodigo.substring(0, 5));
                      $scope.data.codigo = parseInt(strCodigo.substring(5));
                      validadorService.validar( $scope.data ).then( function ( response ) {
                        if (response.status == 'OK') {
                          toastr.success( 'Código de invitación válida' );
                          $scope.valido = 1;
                        } else {
                          toastr.error( response.message );
                          $scope.valido = 0;
                        }
                        $scope.codigo = $scope.data.general;
                        $scope.mensaje = response.message;
                        $scope.data = {
                          correlativo : 0,
                          codigo : 0,
                          general : null,
                          estadoId : 0
                        };
                        focus('general');
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  }
                  focus('general');
                } //end
              ]
            }
          }
        })
    }
  ]
);
