var myApp=angular.module('app.agenda', [
  'ui.router',
  'toastr',
  'app.agendaService',
  'app.authService',
  'app.tablero'
])
.filter( 'dateFilter', ['$filter', function ($filter) {
  return function (input) {
    if (!input) {
      return '';
    } else {
      return $filter( 'date' )(new Date(input),'dd/MM/yyyy' );
    }
  };
}]).filter( 'dateTimeFilter', ['$filter', function ($filter) {
  return function (input) {
    if (!input) {
      return '';
    } else {
      return $filter( 'date' )(new Date(input),'dd/MM/yyyy HH:mm:ss' );
    }
  };
}])
.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      //$sceDelegateProvider.resourceUrlWhitelist(['**']);
      $stateProvider
        .state('index.agenda', {

          abstract: true,

          url: 'agenda',

          params: {

          },

          views: {
            '': {
              templateUrl: 'app/agenda/agenda.tpl.html',
              resolve: {
              },
              controller: ['$scope','$window', '$state', 'toastr',
                function (  $scope, $window,  $state,   toastr) {


                  $.AdminLTE.options.sidebarExpandOnHover;//=false;
                  $scope.setTabs = function (edit, id) {
                    var linkSesion;
                    var linkDetalle;
                    if(edit==2)
                    {
                      linkSesion='index.agenda.view({id:'+id+'})';
                      linkDetalle='index.agenda.viewdetalle({id:'+id+'})';
                    }
                    else {
                      linkSesion=(!edit ?'index.agenda.add':'index.agenda.edit({id:'+id+'})');
                      linkDetalle=(!edit ?'index.agenda.adddetalle': 'index.agenda.editdetalle({id:'+id+'})');
                    }

                    var tabs = [
                        { name: 'Sesión', link: linkSesion,  visible: true, disabled: false  },
                        { name: 'Vista Previa Sesión', link: linkDetalle,  visible: true, disabled: false },
                    ];
                    //descomentar para mostrar los tabs
                    $scope.tabs = tabs;
                  }
                  //$scope.setTabs(false,0);
                  $scope.sesion=
                  {
                    anio:0,
                    correlativo:0,
                    descripcion:null,
                    estado:0,
                    fechaHora:null,
                    sesionId:0,
                    tipoSesion:0,
                    ttDetalleSesions : [],//ttArchivoAdjuntos:[],
                    urlEncabezado:null
                  };
                  $scope.limpiar=function()
                  {
                    $scope.sesion=
                    {
                      anio:0,
                      correlativo:0,
                      descripcion:null,
                      estado:0,
                      fechaHora:null,
                      sesionId:0,
                      tipoSesion:0,
                      ttDetalleSesions : [],//ttArchivoAdjuntos:[],
                      urlEncabezado:null
                    };
                  };
                }]
            }
          }

        }).state( 'index.agenda.list', {
          url: '/list',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.list.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(2);
                  }],


              },
              controller: ['$scope', '$state', 'toastr', 'agendaService','$filter','calendarConfig',
                function (  $scope,   $state,   toastr,   agendaService,$filter,calendarConfig) {

                  //calendarConfig.dateFormatter = 'moment'; // use moment to format dates
                  //moment.locale('es_GT');
                  calendarConfig.i18nStrings.weekNumber="Sem. {week}";

                  $scope.inicio = {};
                  $scope.inicio.inicializando = 1;
                  //http://stackoverflow.com/questions/29345481/control-or-disable-browser-back-button-with-javascript-or-angularjs#29345506
                  $scope.$on('$locationChangeStart', function(event, next, current){
                    if ($scope.inicio.inicializando == 0) {
                      alert('Lo sentimos, no es una opción válida');
                      event.preventDefault();
                    } else {
                      $scope.inicio.inicializando = 0;
                    }
                  });
                  /*Grid de sesiones de pleno*/
                  $scope.gridOptionsSesiones = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsSesiones.columnDefs = [
                    { name: 'sesionId', displayName: "ID sesion" },
                    //{ name: 'proceso', displayName: "Proceso" },
                    { name: 'fecha', displayName: "Fecha",type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\''  },
                    { name: 'observaciones', displayName: "Observaciones" },
                    { name: 'usuarioIng', displayName: "Usuario" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.edit(row.entity)" title="Editar"><i class="fa fa-edit"></i></button> <button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.delete(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];

                  $scope.calendarView = 'month';
                  $scope.calendarDay= new Date();
                  //funciones agregadas para el calendario
                  $scope.calendario = function ( data )
                  {
                    $scope.events = [];
                    var conteo = 0;
                    var anterior = 0;
                    var color = null;
                    data.sort(function(a, b){return new Date(a.fechaHora)-new Date(b.fechaHora);});
                    for (var i = 0; i < data.length; i++)
                    {
                      var inicio =  data[i].fechaHora;//new Date(parseInt(data[i].fecha.substr(6)));
                      var f_i = $filter( 'date' )(new Date(inicio),'dd/MM/yyyy' );
                      var h_i = $filter( 'date' )(new Date(inicio),'HH:mm' );
                      /*var fin =  new Date(parseInt(data[i].FECHA_HORA_FIN.substr(6)));
                      var f_f = $filter( 'date' )(new Date(fin),'dd/MM/yyyy' );
                      var h_f = $filter( 'date' )(new Date(fin),'HH:mm' );*/

                      var date= f_i;
                      var parts = date.split("/");
                      var time=  h_i;
                      var tparts= time.split(":");
                      var dates = new Date(parts[2], parts[1]-1, parts[0], tparts[0], tparts[1]);


                          /*$scope.events.push({
                            title:data[i].sesionId,
                            type:'info',//data[i].type,
                            startsAt: new Date(data[i].fechaHora),//dates,
                            //endsAt:dates_f,
                            incrementsBadgeTotal: true,
                            allday: false,
                            sesion: "Sesión "+ ((data[i].tipoSesion==1)?"Ordinaria":"Extraordinaria")+" "+data[i].correlativo+"-"+data[i].anio,
                            //anio: data[i].anio,
                            //numero: data[i].correlativo,
                            //tipo: data[i].tipoSesion==1?"Ordinaria":"Extraordinaria",

                            editable: true, // If edit-event-html is set and this field is explicitly set to false then dont make it editable.
                            deletable: false, // If delete-event-html is set and this field is explicitly set to false then dont make it deleteable
                            draggable: true, //Allow an event to be dragged and dropped
                            resizable: true, //Allow an event to be resizable
                            mostrar:''
                          });*/
                          if (conteo > 2 || anterior != parts[0]) {
                            conteo = 0;
                          }
                          switch (conteo) {
                            case 1:
                              color = {primary: '#F5F6CE', secondary: '#F5F6CE'};
                              break;
                            case 0:
                              color = {primary: '#E0ECF8', secondary: '#E0ECF8'};
                              break;
                            case 2:
                              color = {primary: '#A9BCF5', secondary: '#A9BCF5'};
                              break;
                            default:

                          }

                          conteo++;
                          anterior = parts[0];
                          $scope.events.push({
                            title:"Sesión "+ ((data[i].tipoSesion==1)?"Ordinaria":"Extraordinaria")+" "+data[i].correlativo+"-"+data[i].anio,
                            type:'info',//data[i].type,
                            color: color,
                            startsAt: new Date(data[i].fechaHora),//dates,
                            //endsAt:dates_f,
                            incrementsBadgeTotal: true,
                            allday: false,
                            sesion: data[i].sesionId,

                            editable: true, // If edit-event-html is set and this field is explicitly set to false then dont make it editable.
                            deletable: false, // If delete-event-html is set and this field is explicitly set to false then dont make it deleteable
                            draggable: true, //Allow an event to be dragged and dropped
                            resizable: true, //Allow an event to be resizable
                            mostrar: data[i].sesionId,
                            label: tparts[0]+":"+tparts[1]//data[i].correlativo+"-"+data[i].anio
                          });


                   } //end for
                 };
                 $scope.llenacalendario=function ()
                 {
                    agendaService.list(2).then( function ( result )
                    {
                      $scope.calendario( result.data );
                    }, function ( error ) {
                      toastr.error( error );
                    });
                };

                $scope.llenacalendario();
                $scope.busqueda = function ( evento ) {
                  if (evento.sesion != 'LIBRE' ) {
                    $state.go("index.agenda.view",{id : evento.sesion})
                  }
                };
                $scope.new = function(){
                      $state.go("index.agenda.add");
                  }
                } //end
              ]
            }
          }
        })
        .state( 'index.agenda.historico', {
          url: '/historico',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.historico.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(4);
                  }],
                agenda: ['agendaService',
                  function( agendaService){
                    return agendaService.historico(2);
                  }],

              },
              controller: ['$scope', '$state', 'toastr', 'agendaService','agenda','$filter', 'calendarConfig',
                function (  $scope,   $state,   toastr,   agendaService,agenda,$filter, calendarConfig) {
                  /*Grid de sesiones de pleno*/
                  $scope.gridOptionsSesiones = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsSesiones.columnDefs = [
                    { name: 'sesionId', displayName: "ID sesion" },
                    //{ name: 'proceso', displayName: "Proceso" },
                    { name: 'fecha', displayName: "Fecha",type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\''  },
                    { name: 'observaciones', displayName: "Observaciones" },
                    { name: 'usuarioIng', displayName: "Usuario" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.edit(row.entity)" title="Editar"><i class="fa fa-edit"></i></button> <button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.delete(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];
                  $scope.calendarView = 'month';
                  $scope.calendarDay= new Date();
                  //funciones agregadas para el calendario
                  $scope.calendario = function ( data )
                  {
                    $scope.events = [];
                    for (var i = 0; i < data.length; i++)
                    {
                      var inicio =  data[i].fechaHora;//new Date(parseInt(data[i].fecha.substr(6)));
                      var f_i = $filter( 'date' )(new Date(inicio),'dd/MM/yyyy' );
                      var h_i = $filter( 'date' )(new Date(inicio),'HH:mm' );
                      /*var fin =  new Date(parseInt(data[i].FECHA_HORA_FIN.substr(6)));
                      var f_f = $filter( 'date' )(new Date(fin),'dd/MM/yyyy' );
                      var h_f = $filter( 'date' )(new Date(fin),'HH:mm' );*/

                      var date= f_i;
                      var parts = date.split("/");
                      var time=  h_i;
                      var tparts= time.split(":");
                      var dates = new Date(parts[2], parts[1]-1, parts[0], tparts[0], tparts[1]);


                          $scope.events.push({
                            title:data[i].sesionId,
                            type:'info',//data[i].type,
                            color: calendarConfig.colorTypes.info,
                            startsAt: new Date(data[i].fechaHora),//dates,
                            //endsAt:dates_f,
                            incrementsBadgeTotal: true,
                            allday: false,
                            sesion: "Sesión "+ ((data[i].tipoSesion==1)?"Ordinaria":"Extraordinaria")+" "+data[i].correlativo+"-"+data[i].anio,
                            //anio: data[i].anio,
                            //numero: data[i].correlativo,
                            //tipo: data[i].tipoSesion==1?"Ordinaria":"Extraordinaria",
                            //proceso: data[i].proceso,
                            //estado: data[i].tcEstadoVid.nombre,
                            //ubicacion: data[i].ubicacion.nombre,
                            //idExpediente: data[i].NUMERO_UNICO,
                            //juez: data[i].LITIERAL_DSC,
                            editable: true, // If edit-event-html is set and this field is explicitly set to false then dont make it editable.
                            deletable: false, // If delete-event-html is set and this field is explicitly set to false then dont make it deleteable
                            draggable: true, //Allow an event to be dragged and dropped
                            resizable: true, //Allow an event to be resizable
                            mostrar:''
                          });

                   } //end for
                 };
                 $scope.llenacalendario=function ()
                 {
                    agendaService.historico(2).then( function ( result )
                    {
                      $scope.calendario( result.data );
                    }, function ( error ) {
                      toastr.error( error );
                    });
                };

                $scope.llenacalendario();
                $scope.busqueda = function ( evento ) {
                  if (evento.sesion != 'LIBRE' ) {
                    $state.go("index.agenda.view",{id : evento.sesion})
                  }
                };
                $scope.new = function(){
                      $state.go("index.agenda.add");
                  }
                } //end
              ]
            }
          }
        })
        .state( 'index.agenda.listadmin', {
          url: '/admin',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.listadmin.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(6);
                  }]
                },
              controller: ['$scope', '$state', 'toastr', 'agendaService','$filter', 'calendarConfig',
                function (  $scope,   $state,   toastr,   agendaService,$filter, calendarConfig) {
                  /*Grid de sesiones de pleno*/
                  var vm=this;
                  calendarConfig.i18nStrings.weekNumber="Sem. {week}";
                  $scope.gridOptionsSesiones = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsSesiones.columnDefs = [
                    { name: 'sesionId', displayName: "ID sesion" },
                    //{ name: 'proceso', displayName: "Proceso" },
                    { name: 'fecha', displayName: "Fecha",type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\''  },
                    { name: 'observaciones', displayName: "Observaciones" },
                    { name: 'usuarioIng', displayName: "Usuario" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.edit(row.entity)" title="Editar"><i class="fa fa-edit"></i></button> <button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.delete(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];
                  $scope.viewDate=new Date();
                  $scope.calendarView = 'month';
                  $scope.calendarDay= new Date();
                  //calendarConfig.templates.calendarMonthCellEvents = 'mwl/calendarMonthCell.html';
                  //console.log('tpl---------'+calendarConfig.templates.calendarMonthCell);
                  /*$scope.$on('$destroy', function() {
                    calendarConfig.templates.calendarMonthCell = 'mwl/calendarMonthCell.html';
                  });*/
                  //funciones agregadas para el calendario
                  $scope.calendario = function ( data )
                  {
                    $scope.events = [];
                    var conteo = 0;
                    var anterior = 0;
                    var color = null;
                    data.sort(function(a, b){return new Date(a.fechaHora)-new Date(b.fechaHora);});
                    for (var i = 0; i < data.length; i++)
                    {
                      var inicio =  data[i].fechaHora;//new Date(parseInt(data[i].fecha.substr(6)));
                      var f_i = $filter( 'date' )(new Date(inicio),'dd/MM/yyyy' );
                      var h_i = $filter( 'date' )(new Date(inicio),'HH:mm' );
                      /*var fin =  new Date(parseInt(data[i].FECHA_HORA_FIN.substr(6)));
                      var f_f = $filter( 'date' )(new Date(fin),'dd/MM/yyyy' );
                      var h_f = $filter( 'date' )(new Date(fin),'HH:mm' );*/

                      var date= f_i;
                      var parts = date.split("/");
                      var time=  h_i;
                      var tparts= time.split(":");
                      var dates = new Date(parts[2], parts[1]-1, parts[0], tparts[0], tparts[1]);
                      if (conteo > 2 || anterior != parts[0]) {
                        conteo = 0;
                      }
                      switch (conteo) {
                        case 1:
                          color = {primary: '#F5F6CE', secondary: '#F5F6CE'};
                          break;
                        case 0:
                          color = {primary: '#E0ECF8', secondary: '#E0ECF8'};
                          break;
                        case 2:
                          color = {primary: '#A9BCF5', secondary: '#A9BCF5'};
                          break;
                        default:
                      }

                      conteo++;
                      anterior = parts[0];

                          $scope.events.push({
                            title:"Sesión "+ ((data[i].tipoSesion==1)?"Ordinaria":"Extraordinaria")+" "+data[i].correlativo+"-"+data[i].anio,
                            type:'info',//data[i].type,
                            color: color,
                            startsAt: new Date(data[i].fechaHora),//dates,
                            //endsAt:dates_f,
                            incrementsBadgeTotal: true,
                            allday: false,
                            sesion: data[i].sesionId,

                            editable: true, // If edit-event-html is set and this field is explicitly set to false then dont make it editable.
                            deletable: false, // If delete-event-html is set and this field is explicitly set to false then dont make it deleteable
                            draggable: true, //Allow an event to be dragged and dropped
                            resizable: true, //Allow an event to be resizable
                            mostrar: data[i].sesionId,
                            label: tparts[0]+":"+tparts[1]//data[i].correlativo+"-"+data[i].anio
                          });

                   } //end for
                 };
                 $scope.cellModifier = function(cell) {

                   if(cell.events.length>0)
                      console.log(cell);

                  cell.label =  cell.label ;
                };
                 $scope.llenacalendario=function ()
                 {
                    agendaService.list().then( function ( result )
                    {
                      $scope.calendario( result.data );
                    }, function ( error ) {
                      toastr.error( error );
                    });
                };

                $scope.trustSrc = function(src) {
                  return $sce.trustAsResourceUrl(src);
                }

                $scope.eventosDia = function ( eventos ) {
                  return $scope.trustSrc('You can see that I am <b>Bold </b> and <I>Italic </I> also');
                }

                $scope.llenacalendario();
                $scope.busqueda = function ( evento )
                {
                  if (evento.sesion != 'LIBRE' ) {
                    $state.go("index.agenda.edit",{id : evento.sesion});
                  }
                };
                $scope.findInArraybyDate = function(date, array)
                {
                    var res=false;
                     for(item in array)
                     {
                       var newDate=new Date(array[item].startsAt);
                       newDate.setHours(0,0,0,0);
                       if(+newDate===+date)
                       {
                         return true;

                       }
                     }
                     return res;
                 }
                $scope.agregarSesion = function ( fecha )
                {
                    fecha.setHours(8);
                    $scope.$parent.fechaAdd=fecha;
                    $scope.$parent.anio = new Date(fecha).getFullYear();
                    $state.go("index.agenda.add");
                };
                $scope.new = function(){
                      $state.go("index.agenda.add");
                  }
                } //end
              ]
            }
          }
        })
        .state( 'index.agenda.add', {
          url: '/add',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.add.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(6);
                  }],
                correlativo: ['agendaService',
                  function( agendaService){
                    return agendaService.sigCorrelativo();
                  }],
                  /*tipos: ['agendaService',
                    function( agendaService){
                      return agendaService.tipos();
                    }],*/
              },
              controller: ['$scope', '$state', 'toastr', 'agendaService','uiGridTreeBaseConstants','ngDialog','$http', '$q', 'appSettings','correlativo','uiGridTreeBaseService','authService','$filter',
                function (  $scope,   $state,   toastr,   agendaService, uiGridTreeBaseConstants,ngDialog,$http, $q, appSettings,correlativo,uiGridTreeBaseService,authService,$filter) {
                  $scope.setTabs(false,0);//setea los tabs
                  //$scope.adjuntos=[];
                  $scope.sesion={};
                  if ($scope.$parent.fechaAdd == null) {
                    $scope.sesion.fechaHora = new Date();
                    $scope.sesion.anio = new Date().getFullYear();
                  } else {
                    $scope.sesion.fechaHora= $scope.$parent.fechaAdd;
                    $scope.sesion.anio=$scope.$parent.anio;
                  }

                  $scope.archivo={};
                  //$scope.sesion={};
                  $scope.sesion.tipoSesion=1;
                  $scope.myFile={};
                  //$scope.myFile2={};
                  $scope.uploadFile={};

                  if(correlativo.status=='OK')
                  {
                    //$scope.sesion.anio=correlativo.data[0].anio;
                    $scope.sesion.correlativo=correlativo.data[0].correlativo;
                  }

                  $scope.actualizarAnio = function () {
                    $scope.sesion.anio = new Date($scope.sesion.fechaHora).getFullYear();
                  }

                  $scope.upload = function(file){
                      toastr.success( "Archivo cargado correctamente." + file );
                  };
                  $scope.uploadFile = function(files){

                      var file = $scope.myFile;
                      if(file==null || file.name ==null)
                      {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }
                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        /*$scope.uploadFile=response.data;*/
                        //$scope.adjuntos.push(response.data[0]);
                        //$scope.gridOptionsAdjuntos.data=$scope.adjuntos;
                        //$scope.adjuntosHide=false;
                        //$scope.myFile=undefined;
                        $scope.sesion.urlEncabezado =response.data[0].url;
                      }, function ( error ) {
                        toastr.error( error );
                      });
                  };
                  /*$scope.addFile = function(){

                      var file = $scope.myFile2;
                      if(file==null || file.name ==null)
                      {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }
                      if($scope.archivo==null || $scope.archivo.descripcion ==null)
                      {
                        toastr.error("Ingrese la descripción del archivo");
                        return;
                      }



                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        response.data[0].descripcion=$scope.archivo.descripcion;
                        $scope.adjuntos.push(response.data[0]);
                        $scope.gridOptionsAdjuntos.data=$scope.adjuntos;
                        $scope.myFile2=undefined;
                      }, function ( error ) {
                        toastr.error( error );
                      });
                  };*/
                  agendaService.tipos().then( function ( response ) {
                    $scope.tipos=response.data;
                  }, function ( error ) {
                    toastr.error( error );
                  });

                  $scope.submitForm = function ( isValid ) {
                    if ( isValid ) {
                      /*if($scope.sesion.urlEncabezado==null)
                      {
                        toastr.error( "Debe elegir un archivo de la sesión" );
                        return;
                      }*/
                      //$scope.sesion.ttArchivoAdjuntos=$scope.adjuntos;

                      agendaService.add( $scope.sesion ).then( function ( response ) {
                        if(response.status=='OK')
                        {
                          toastr.success( response.message );
                          $scope.limpiar();
                          $state.go("index.agenda.edit",{id:response.data[0].sesionId})
                        }
                        else {
                            toastr.error( response.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  };

                  /*******************Del detalle*******************************/
                  $scope.detalle={};
                  $scope.myFile2={};
                  $scope.index= null;
                  $scope.aux=-1;
                  $scope.detalles=[];//$scope.sesion.ttDetalleSesions;
                  $scope.sesion.ttDetalleSesions=$scope.detalles;
                  //$scope.sesion={};
                  $scope.gridOptionsDetalle = angular.copy( $scope.gridOptionsSelection);
                  $scope.gridOptionsDetalle.showTreeExpandNoChildren= true;
                  $scope.gridOptionsDetalle.showTreeRowHeader= false;
                  $scope.gridOptionsDetalle.enableRowHeaderSelection= false;
                  $scope.gridOptionsDetalle.enableColumnResizing=true;
                  $scope.gridOptionsDetalle.columnDefs=[];
                  $scope.gridOptionsDetalle.columnDefs = [
                    { name: 'tree' , width:'5%', enableCellEdit:false, enableSorting:false, headerCellTemplate:'<div></div>', cellTemplate : '<div class="ui-grid-cell-contents" title="TOOLTIP" ><div style="float:left;" class="ui-grid-tree-base-row-header-buttons" ng-class="{\'ui-grid-tree-base-header\': row.treeLevel > -1 }" ng-click="grid.appScope.toggleRow(row,evt)"><i ng-class="{\'ui-grid-icon-minus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || ( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'expanded\', \'ui-grid-icon-plus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || ( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'collapsed\'}" ng-style="{\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}"></i> &nbsp;</div></div>' },
                    { name: 'numeracion', displayName: "No.",   width:'15%' },
                    { name: 'descripcion', displayName: "Descripción",width:'55%' },
                    { name: 'OPCIONES', enableFiltering: false,enableCellEdit:false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn btn-default btn-xs text-blue" ng-click="grid.appScope.deleteDetalle(row.entity)" title="Eliminar"><i class="zmdi zmdi-delete"></i></button><button type="button" class="btn btn-default btn-xs text-green" ng-click="grid.appScope.addDetalle(row.entity)" title="Agregar"><i class="fa fa-plus"></i></button><button type="button" class="btn btn-default btn-xs text-yellow" ng-click="grid.appScope.addAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="fa fa-folder-open "></i></button><button type="button" class="btn bg-light-blue-active btn-xs" ng-if="row.entity.allowAdjuntos && (row.entity.ttArchivoAdjuntos.length==0 || row.entity.ttArchivoAdjuntos==null)" ng-click="grid.appScope.addAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="fa fa-folder"></i></button><button type="button" ng-if="grid.appScope.esSecretario()&&grid.appScope.existeComentarioDetalle(row.entity)" class="btn bg-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label label-danger text-center span">1</span></button><button type="button" ng-if="grid.appScope.esSecretario()&&!grid.appScope.existeComentarioDetalle(row.entity)" class="btn bg-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label text-center span">..</span></button></span></div>', width:'25%' },

                  ];

                  $scope.expandAll = function(){
                    $scope.gridApi.treeBase.expandAllRows();
                  };

                  $scope.toggleRow = function( row,evt ){
                    uiGridTreeBaseService.toggleRowTreeState($scope.gridApi.grid, row, evt);
                    //$scope.gridApi.treeBase.toggleRowTreeState($scope.gridApi.grid.renderContainers.body.visibleRowCache[rowNum]);
                  };

                  $scope.toggleExpandNoChildren = function(){
                    $scope.gridOptions.showTreeExpandNoChildren = !$scope.gridOptions.showTreeExpandNoChildren;
                    $scope.gridApi.grid.refresh();
                  };
                  $scope.findInArray2 = function(condicion, array) {
                      var res;
                       var found = $filter('filter')(array, condicion, true);
                       if (found.length) {
                           res = found[0];
                       } else {
                           res   = 'Not found';
                       }
                       return res;
                   }
                  $scope.addComentario=function (row) {
                    //$scope.gridOptionsAdjuntos.data=row.ttArchivoAdjuntos;
                    $scope.detalle=row;
                    var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                    if(res.length>0) {
                      $scope.existComment=true;
                    } else {
                      $scope.existComment=false;
                      $scope.sesion.comentarioUsuarioId=res.comentarioUsuarioId;
                    }
                    $scope.sesion.comentario=res.comentario;
                    $scope.sesion.detalleSesionId=row.detalleSesionId;
                    $scope.sesion.objcomentario=res;
                    $scope.sesion.comentarios=row.ttComentarioUsuario;
                    ngDialog.open({
                       template: 'app/agenda/agenda.comentario.tpl.html',
                       className: 'ngdialog-theme-flat',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '70%',
                       height:'60%',
                       scope: $scope
                     });
                  }
                  $scope.guardarComentario=function() {
                    if($scope.sesion.comentario==null)
                      toastr.error("Ingrese el texto del comentario.");
                    else
                    {
                      var a={
                        comentario:$scope.sesion.comentario,
                        detalleSesionId:$scope.sesion.detalleSesionId,
                        usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                        puntoVisto : 0,
                        puntoAprobado : 0
                      };
                      agendaService.addComentario(a).then( function ( result )
                      {
                        if(result.status=='OK')
                        {
                          //$scope.sesion.objcomentario.comentario=a.comentario;
                          a.comentarioUsuarioId=result.data[0].comentarioUsuarioId;
                          $scope.sesion.comentarios.push(a);
                          toastr.success("Comentario almacenado con exito");
                          ngDialog.close();
                        }
                        else
                        {
                          toastr.error( "Error al almacenar el comentario:"+result.message );
                        }

                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }

                  }
                  $scope.actualizarComentario=function()
                  {
                    var a={
                      comentarioUsuarioId:$scope.sesion.comentarioUsuarioId,
                      comentario:$scope.sesion.comentario,
                      detalleSesionId:$scope.sesion.detalleSesionId,
                      usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                    };
                    agendaService.updComentario(a).then( function ( result )
                    {
                      if(result.status=='OK')
                      {
                        $scope.sesion.objcomentario.comentario=a.comentario;
                        toastr.success("Comentario actualizado con exito");
                      }
                      else
                      {
                        toastr.error( "Error al actualizar el comentario:"+result.message );
                      }

                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                  $scope.cerrarComentario=function()
                  {
                    ngDialog.close();
                  }
                  $scope.existeComentarioDetalle= function(row)
                  {
                    var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                    if( res.comentario != null ) {
                      /*if (res.comentario == ".") {
                        return false;
                      }*/
                      return true;
                    } else {
                      return false;
                    }
                  }

                  $scope.esSecretario= function()
                  {
                    if(appSettings.perfilId!=5)
                      return false;
                    else {
                      return true;
                    }
                  }
                  $scope.gridOptionsDetalle.data=$scope.detalles;
                  /*opciones*/

                  /*opciones*/

                  $scope.getDetalleSesionId2=function()
                  {
                    agendaService.getDetalleSesionId().then( function ( result )
                    {
                      $scope.aux=result.data[0];
                    }, function ( error ) {
                      toastr.error( error );
                    });

                  }

                  $scope.addDetalle=function(row)
                  {
                    var filaActual = null;
                    var index = -1;
                    var padre = { detalleSesionId: null};
                    var esItemPrincipal = 0;

                    if (row != null) {
                      // Se quiere agregar una fila como sub-item
                      filaActual = row;
                      index = $scope.detalles.indexOf(filaActual);
                      padre.detalleSesionId = filaActual.detalleSesionId;
                    }
                    else {
                      // Se quiere agregar una fila como item principal después de la fila actual
                      var currentSelection = $scope.gridApi.selection.getSelectedRows();
                      if (currentSelection.length > 0) {
                        filaActual = currentSelection[0];
                      }
                      // Se activa la bandera principal para indicar que es un item principal
                      esItemPrincipal = 1;
                    }

                    agendaService.getDetalleSesionId().then( function ( result )
                    {
                        var i = {
                          detalleSesionId: result.data[0].secuencia,
                          ttDetallePadre: padre.detalleSesionId == null?null:padre,
                          usuarioCreaId: authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                          ttComentarioUsuario: []
                        };

                        if(esItemPrincipal == 1) {
                          i.$$treeLevel = 0;
                        }
                        else {
                          i.$$treeLevel = filaActual.$$treeLevel + 1;
                        }

                        if(index >= 0){
                          $scope.detalles.splice(index + 1, 0, i);
                        } else {
                          $scope.detalles.push(i);
                        }

                        $scope.gridOptionsDetalle.data = $scope.detalles;
                    }, function ( error ) {
                        toastr.error( error );
                    });
                  };

                  $scope.deleteDetalle=function(row)
                  {
                    var index = $scope.gridOptionsDetalle.data.indexOf(row);
                    $scope.gridOptionsDetalle.data.splice( index , 1 );
                    $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                  }


                  $scope.addAdjuntos=function (row)
                  {
                    var index = $scope.sesion.ttDetalleSesions.indexOf(row);
                    $scope.index=index;
                    $scope.detalle=row;
                    $scope.gridOptionsAdjuntos.data=$scope.detalle.ttArchivoAdjuntos;
                    ngDialog.open({
                       template: 'app/agenda/agenda.addadjuntos.tpl.html',
                       //className: 'ngdialog-theme-default',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '70%',
                       height:600,
                       //showClose: false,
                       scope: $scope
                     });
                  }
                  $scope.cerrarAdjuntos=function()
                  {
                    ngDialog.close();
                  }
                  /*sin modificar la parte de adjuntos*/
                  $scope.archivo={};
                  //$scope.myFile2={};
                  //$scope.adjuntos=[];
                  $scope.gridOptionsAdjuntos = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsAdjuntos.columnDefs = [
                    { name: 'folios', displayName: "Folios", width:"5%"  },
                    { name: 'descripcion', displayName: "Descripción", width: "40%", },
                    { name: 'url', displayName: "Archivo", width: "40%" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.deleteAdjunto(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];

                  $scope.getTableHeight = function() {
                    var rowHeight = 80; // your row height
                    var headerHeight = 80; // your header height
                    var rows = $scope.gridOptionsAdjuntos.data.length;
                    if (rows <= 0) {
                      rows = 1;
                    } else {
                      if (rows > appSettings.paginationPageSize) {
                        rows = appSettings.paginationPageSize;
                      }
                    }
                    return {
                      height: (rows * rowHeight + headerHeight) + "px"
                    };
                  };

                  //$scope.gridOptionsAdjuntos.data=$scope.sesion.detalle.ttArchivoAdjuntos;
                  $scope.addFile = function(){

                      var file = $scope.$$childTail.myFile2;
                      if(file==null || file.name ==null)
                      {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }
                      if($scope.archivo==null || $scope.archivo.descripcion ==null)
                      {
                        toastr.error("Ingrese la descripción del archivo");
                        return;
                      }
                      if($scope.archivo==null || $scope.archivo.folios ==null)
                      {
                        toastr.error("Ingrese el número de folios del archivo");
                        return;
                      }



                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        response.data[0].descripcion=$scope.archivo.descripcion;
                        response.data[0].folios=$scope.archivo.folios;
                        response.data[0].usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        //$scope.sesion.ttArchivoAdjuntos.push(response.data[0]);
                        //$scope.gridOptionsAdjuntos.data=$scope.sesion.ttArchivoAdjuntos;
                        var adjuntos= $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos;
                        if(adjuntos ==null)
                        {
                            $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos=[];
                        }
                        $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos.push(response.data[0])
                        $scope.gridOptionsAdjuntos.data=$scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos;
                        $scope.$$childTail.myFile2={};
                        $scope.archivo.descripcion=null;
                        $scope.archivo.folios=0;
                      }, function ( error ) {
                        toastr.error( error );
                      });
                  };
                  /*********************************************************/
                  $scope.deleteAdjunto = function( row ) {

                    //$scope.sesion.ttArchivoAdjuntos.push(response.data[0]);
                    //$scope.gridOptionsAdjuntos.data=$scope.sesion.ttArchivoAdjuntos;
                      var index = $scope.detalle.ttArchivoAdjuntos.indexOf(row);
                      $scope.detalle.ttArchivoAdjuntos.splice( index , 1 );
                      $scope.gridOptionsAdjuntos.data=$scope.detalle.ttArchivoAdjuntos;

                  }
                } //end
              ]
            }
          }
        })
        .state( 'index.agenda.edit', {
          url: '/:id/edit',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.edit.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(6);
                  }],
                sesion: ['agendaService', '$stateParams',
                  function( agendaService, $stateParams){
                    //if($scope.sesion==null)
                      $dataSesion = agendaService.get( $stateParams.id);//usuarioId=1
                    //else $dataSesion=$scope.sesion;
                    return $dataSesion;
                  }],
              },
              controller: ['$rootScope','$scope', '$window', '$state', 'toastr', 'agendaService','$stateParams','sesion','$stateParams', '$sce',
                'ngDialog','$filter','uiGridTreeBaseService','authService', 'appSettings','localStorageService',
                function ( $rootScope, $scope,  $window,  $state,   toastr,   agendaService,$stateParams,sesion,$stateParams, $sce,
                  ngDialog, $filter, uiGridTreeBaseService, authService, appSettings,localStorageService) {
                  /*if($scope.current.sesion.sesionId!=$stateParams.id)
                    $scope.current.sesion=sesion.data[0];*/
                  //$rootScope.setPreventExit(false);

                  $scope.sesion=sesion.data[0];
                  //$scope.sesion=$scope.current.sesion
                  //$scope.sesion.fechaHora=$filter( 'date' )(new Date($scope.sesion.fechaHora),'dd/MM/yyyy HH:mm' );
                  $scope.setTabs(true,$stateParams.id);
                  $scope.adjuntos=[];
                  $scope.archivo={};
                  //$scope.sesion={};
                  $scope.myFile={};
                  $scope.myFile2={};
                  $scope.uploadFile={};
                  //$scope.comentario={comentario:{}};
                  //$scope.tipos=tipos.data;
                  /*grid de archivos adjuntos*/
                  $scope.gridOptionsAdjuntos = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsAdjuntos.columnDefs = [
                    { name: 'folios', displayName: "Folios" },
                    { name: 'descripcion', displayName: "Descripción", width: "45%", },
                    { name: 'url', displayName: "Archivo", width: "30%" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.deleteAdjunto(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];

                  $scope.abrirTablero = function () {
                    localStorageService.set('detalles',JSON.stringify($scope.sesion.ttDetalleSesions));
                    var url = $state.href('index.tablero.list', { sesionId : $scope.sesion.sesionId });
                    window.open(url, '_blank');
                  }

                  /**/

                  $scope.upload = function(file){
                      toastr.success( "Archivo cargado correctamente." + file );
                  };
                  $scope.uploadFile = function(files) {
                      var file = $scope.myFile;
                      if(file==null || file.name ==null) {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }
                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        /*$scope.uploadFile=response.data;*/
                        $scope.adjuntos.push(response.data[0]);
                        //$scope.gridOptionsAdjuntos.data=$scope.adjuntos;
                        $scope.adjuntosHide=false;
                        //$scope.myFile=undefined;
                      }, function ( error ) {
                        toastr.error( error );
                      });
                  };
                  $scope.addFile = function(){

                      var file = $scope.myFile2;
                      if(file==null || file.name ==null)
                      {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }
                      if($scope.archivo==null || $scope.archivo.descripcion ==null)
                      {
                        toastr.error("Ingrese la descripción del archivo");
                        return;
                      }
                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        response.data[0].descripcion=$scope.archivo.descripcion;
                        response.data[0].temp=true;
                        $scope.adjuntos.push(response.data[0]);
                        $scope.gridOptionsAdjuntos.data=$scope.adjuntos;
                        $scope.myFile2=undefined;
                        $scope.archivo.descripcion={};
                      }, function ( error ) {
                        toastr.error(file+"_______"+ error );
                      });
                  };
                  agendaService.tipos().then( function ( response ) {
                    $scope.tipos=response.data;
                  }, function ( error ) {
                    toastr.error( error );
                  });
                  $scope.deleteAdjunto = function( row ) {
                      var index = $scope.detalle.ttArchivoAdjuntos.indexOf(row);
                      $scope.detalle.ttArchivoAdjuntos.splice( index , 1 );
                      $scope.gridOptionsAdjuntos.data=$scope.detalle.ttArchivoAdjuntos;
                  }

                  $scope.deleteSesion=function() {
                    swal({
                      title: "¿Está seguro que desea eliminar la sesión?",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    },
                    function (isValid) {
                      if ( isValid ) {
                        agendaService.delete( $scope.sesion.sesionId ).then( function ( response ) {
                          if(response.status=="OK")
                          {
                            $rootScope.setExistChanges(false);
                            toastr.success( response.message );
                            $scope.sesion={};
                            $state.go("index.agenda.listadmin")
                          }
                          else {
                              toastr.error( response.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }

                    });
                  }
                  $scope.finalizarSesion= function ()
                  {
                    swal({
                      title: "¿Está seguro que desea publicar la sesión? Se enviará la alerta a los participantes.",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    },
                    function (isValid) {
                      if ( isValid ) {
                        //obtener los ids de los participantes
                        //$scope.sesion.sesionId
                        agendaService.listaParticipantes().then( function ( response ) {
                          if(response.status=="OK")
                          {
                            //obtener datos de los participantes a partir de los ids
                            var aux={
                              sesionId:$scope.sesion.sesionId,
                              //contactos:response.data
                              usuario:response.data.join()
                            };
                            var stringUsuarios="";
                            for(usuario in response.data)
                            {
                              stringUsuarios+=response.data[usuario].usuarioId+",";
                            }
                            stringUsuarios=stringUsuarios.slice(0, -1);
                            aux.usuario=stringUsuarios;
                            agendaService.datosParticipantes(aux).then( function ( response ) {
                              if(response.status=="OK")
                              {
                                //enviar datos para finalizar la sesion
                                var aux2={
                                  sesionId:$scope.sesion.sesionId,
                                  nombreEmisor: "Secretaría de la Corte Suprema de Justicia",
                                  asunto:"Agenda "+$scope.sesion.anio +"-"+$scope.sesion.correlativo,
                                  mensaje: "La agenda electrónica "+$scope.sesion.anio +"-"+$scope.sesion.correlativo+" para el día "+$filter( 'date' )(new Date( $scope.sesion.fechaHora),'dd/MM/yyyy' ) +" ya se encuentra disponible en el sistema para su consulta en la siguiente dirección: http://agendapleno.oj.gob.gt",
                                  contactos:response.data
                                }
                                agendaService.finalizarSesion(aux2).then( function ( response ) {
                                  if(response.status=="OK")
                                  {
                                    toastr.success( response.message );
                                    $rootScope.setExistChanges(false);
                                    //$state.go("index.agenda.listadmin")
                                  }
                                  else {
                                    //error al finalizar la sesion
                                      toastr.error( response.message );
                                  }
                                }, function ( error ) {
                                  //error al finalizar la sesion
                                  toastr.error( error );
                                });
                              }
                              else {
                                  //error al obtener los datos de los participantes
                                  toastr.error( response.message );
                              }
                            }, function ( error ) {
                              //error al obtener los datos de los participantes
                              toastr.error( error );
                            });
                          }
                          else {
                            //error al obtener los ids
                              toastr.error( response.message );
                          }
                        }, function ( error ) {
                          //error al obtener los ids
                          toastr.error( error );
                        });

                      }

                    });
                  }
                  $scope.submitForm=function(isValid)
                  {

                      swal({
                        title: "¿Está seguro que desea actualizar la sesión?",
                        text: "",
                        showCancelButton: true,
                        confirmButtonClass: "btn btn-success",
                        confirmButtonText: "Confirmar",
                        cancelButtonClass: "btn btn-danger",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                      },
                      function (isValid) {
                        if ( isValid ) {


                          agendaService.update( $scope.sesion ).then( function ( response ) {
                            if(response.status=="OK")
                            {
                              toastr.success( response.message );
                              $rootScope.setExistChanges(false);
                              $state.go("index.agenda.edit",{id:$scope.sesion.sesionId},{reload: true});

                            }
                            else {
                                toastr.error( response.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                      });

                  }

                  $scope.setAllowAdjuntos=function(data)
                  {
                    for(row in data)
                    {
                      if(data[row].ttArchivoAdjuntos.length>0)
                        data[row].allowAdjuntos=true;
                      else
                        data[row].allowAdjuntos=false;
                    }
                  }
                  /******************movido de editdetalle por solicitud de cambio*******************************************/
                  $scope.archivo={};
                  //$scope.sesion=$scope.current.sesion;
                  $scope.gridOptionsAdjuntos = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptionsAdjuntos.columnDefs =
                  [
                    { name: 'folios', displayName: "Folios" },
                    { name: 'descripcion', displayName: "Descripción", width: "40%", },
                    { name: 'url', displayName: "Archivo", width: "35%" },
                    { name: 'OPCIONES', enableFiltering: false,
                      cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + '<button type="button" class="btn btn-default btn-xs text-red" ng-click="grid.appScope.deleteAdjunto(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button>'
                      + '<button type="button" class="btn btn-default btn-xs text-green" ng-click="grid.appScope.abrirAdjunto(row.entity)" title="Descargar y abrir adjunto"><i class="fa fa-download"></i></button>'
                      + '</span></div>', width: "15%"
                    }
                  ];

                  $scope.abrirAdjunto = function ( row ) {
                    var urlReporteAgenda;
                    if(row.temp==true)
                      urlReporteAgenda=appSettings.restApiServiceBaseUri+"sesion/filetemp/"+row.url.replace(/\//g,"$");
                    else
                    {
                      urlReporteAgenda=appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId;
                    }
                    //$scope.sesion.path2=encodeURIComponent(urlReporteAgenda)
                    $scope.path2=encodeURIComponent(urlReporteAgenda)

                    urlReporteAgenda='./web/viewer.html?file='+$scope.path2;

                    $scope.trustSrc = function(src) {
                      return $sce.trustAsResourceUrl(src);
                    }
                    $scope.iframe = {src:urlReporteAgenda};
                    ngDialog.open({
                       template: 'app/agenda/agenda.viewdetalle.tpl.html',
                       className: 'ngdialog-theme-flat',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '100%',
                       height:'100%',
                       scope: $scope
                     });
                  }

                  $scope.getTableHeight = function() {
                    var rowHeight = 80; // your row height
                    var headerHeight = 80; // your header height
                    var rows = $scope.gridOptionsAdjuntos.data.length;
                    if (rows <= 0) {
                      rows = 1;
                    } else {
                      if (rows > appSettings.paginationPageSize) {
                        rows = appSettings.paginationPageSize;
                      }
                    }
                    return {
                      height: (row * rowHeight + headerHeight) + "px"
                    };
                  };
                  //$scope.gridOptionsAdjuntos.data=$scope.current.sesion.ttArchivoAdjuntos;

                  /*****************************grid de detalles******************/
                  $scope.detalles=$scope.sesion.ttDetalleSesions;
                  $scope.mostrarOpciones = 0;
                  $scope.gridOptionsDetalle = angular.copy( $scope.gridOptionsSelection);
                  $scope.gridOptionsDetalle.showTreeExpandNoChildren= true;
                  $scope.gridOptionsDetalle.showTreeRowHeader= false;
                  $scope.gridOptionsDetalle.enableColumnResizing=true;
                  $scope.gridOptionsDetalle.enableFiltering = true;
                  //grid secretario
                  $scope.gridOptionsDetalle.columnDefs = [
                    { name: 'tree',width:'8%', enableCellEdit:false,
                      headerCellTemplate:'<div class="ui-grid-cell-contents text-center col-options"><span><div '
                        +'class=" text-white" ng-click="grid.appScope.toggleAll()" title="Expandir/colapsar">'
                        +'<i ng-if="grid.appScope.gridApi.treeBase.expanded" class="ui-grid-icon-minus-squared" ></i>'
                        +'<i ng-if="!grid.appScope.gridApi.treeBase.expanded" class="ui-grid-icon-plus-squared" ></i>'
                        +'</button></span><div>', enableFiltering : false,enableSorting:false,
                      cellTemplate : '<div class="ui-grid-cell-contents" title="Expandir" ><div style="float:left;" '
                      +'class="ui-grid-tree-base-row-header-buttons" ng-class="{\'ui-grid-tree-base-header\': row.treeLevel > -1 }" '
                      +'ng-click="grid.appScope.toggleRow(row,evt)"><i ng-if="row.entity.hasChildren" '
                      +'ng-class="{\'ui-grid-icon-minus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || '
                      +'( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'expanded\','
                      +' \'ui-grid-icon-plus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || '
                      +'( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'collapsed\'}" '
                      +'ng-style="{\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}"></i> &nbsp;</div></div>' },
                    { name: 'numeracion', headerCellTemplate:'<div class="text-center"><h4><b>No.</b></h4></div>',
                      enableSorting : false, displayName: "No.", enableFiltering : false, width:'130',
                      cellTemplate : '<div class="ui-grid-cell-contents" > ' +
                      '<div style="float:left;" ' +
                      'ng-style="{\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}">{{row.entity.numeracion}}</div>'
                    },
                    { name: 'descripcion', displayName: "Descripción", },
                    { name: 'OPCIONES2', enableFiltering: false, enableCellEdit:false,
                      headerCellTemplate:'<div ng-show="grid.appScope.mostrarOpciones > 0">'
                      //+'<button type="button" class="btn btn-default btn-sm text-black icon-grid" ng-click="grid.appScope.addDetalle()" title="Agregar nueva fila"><i class="zmdi zmdi-wrap-text"></i></button>'
                      +'<button type="button" class="btn btn-default btn-sm text-black icon-grid" ng-click="grid.appScope.editRow()" title="Editar"></span><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                      +'<button type="button" class="btn btn-default btn-sm text-black icon-grid" ng-click="grid.appScope.addSubitem()" title="Agregar subitem a la fila"><i class="zmdi zmdi-format-indent-increase"></i></button>'
                      +'<button type="button" class="btn btn-default btn-sm text-black icon-grid" ng-click="grid.appScope.deleteRow()" title="Eliminar fila"></span><i class="zmdi zmdi-delete"></i></button>'
                      +'</div>'
                      +'<div class="text-center" ng-show="grid.appScope.mostrarOpciones < 1"><h4><b>Opciones</b></h4></div>',
                      cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                        //+'<button type="button" class="btn btn-default btn-xs text-black" ng-click="grid.appScope.deleteDetalle(row.entity)" title="Eliminar"><i class="zmdi zmdi-delete"></i></button>'
                        //+'<button type="button" class="btn btn-default btn-xs text-green" ng-click="grid.appScope.addDetalle(row.entity)" title="Agregar"><i class="fa fa-plus"></i></button>'
                        +'<button type="button" ng-if="row.entity.ttArchivoAdjuntos.length==0 || row.entity.ttArchivoAdjuntos == null" class="btn btn-default btn-xs text-yellow" ng-click="grid.appScope.addAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black"></i><span class="label text-center span">..</span></button>'
                        +'<button type="button" ng-if="row.entity.ttArchivoAdjuntos.length>0" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.addAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black"></i><span class="label label-danger span">1</span></button>'
                        //+'<button type="button" class="btn btn-default btn-xs" ng-if="row.entity.allowAdjuntos && (row.entity.ttArchivoAdjuntos.length == 0 || row.entity.ttArchivoAdjuntos ==null)" ng-click="grid.appScope.addAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt"></i></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&grid.appScope.existeComentarioDetalle(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label label-danger text-center span">1</span></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&!grid.appScope.existeComentarioDetalle(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label text-center span">..</span></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&grid.appScope.esVisto(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.marcarVisto(row.entity)" title="Punto marcado como visto"><i class="zmdi zmdi-eye"></i><span class="label label-danger text-center span"><i class="zmdi zmdi-check"></i></span></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&!grid.appScope.esVisto(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.marcarVisto(row.entity)" title="Marcar como punto visto"><i class="zmdi zmdi-eye-off"></i><span class="label text-center span">..</span></button></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&grid.appScope.estaAprobado(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-blue" ng-click="grid.appScope.aprobarPunto(row.entity)" title="Punto aprobado"><i class="zmdi zmdi-check-all"></i><span class="label label-danger text-center"></span></button>'
                        +'<button type="button" ng-disabled="!row.entity.saved" ng-if="grid.appScope.esSecretario()&&!grid.appScope.estaAprobado(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-black" ng-click="grid.appScope.aprobarPunto(row.entity)" title="Aprobar punto"><i class="fa fa-check"></i><span class="label text-center span"></span></button></span></div>',
                      width:'22%'
                    }
                  ];

                  $scope.gridOptionsDetalle.onRegisterApi= function( gridApi ) {
                    $scope.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope,function(row){
                      if (row.isSelected) {
                        $scope.mostrarOpciones = 1;
                      } else {
                        $scope.mostrarOpciones = 0;
                      }
                    });
                    gridApi.selection.on.rowSelectionChangedBatch($scope,function(rows){

                    });
                    gridApi.edit.on.afterCellEdit($scope,function(rowEntity, colDef, newValue, oldValue)
                    {
                        if(newValue != oldValue)
                        {
                            $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                        }
                    });
                  }

                  $scope.addSubitem = function() {
                    var currentSelection = $scope.gridApi.selection.getSelectedRows();
                    if (currentSelection.length > 0) {
                      $scope.addDetalle(currentSelection[0]);
                    } else {
                      toastr.error("Seleccione la fila a la que desea agregar un sub-item");
                    }
                  };

                  $scope.deleteRow = function() {
                    swal({
                      title: "¿Está serguro de eliminar la fila seleccionada?",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    },
                    function (isValid) {
                      if ( isValid ) {
                        var currentSelection = $scope.gridApi.selection.getSelectedRows();
                        if (currentSelection.length > 0) {
                          $scope.deleteDetalle(currentSelection[0]);
                        } else {
                          toastr.error("Seleccione la fila que desea eliminar");
                        }
                        $scope.mostrarOpciones = 0;
                      }
                      else {

                      }

                    });

                  };

                  $scope.editRow = function () {
                    var currentSelection = $scope.gridApi.selection.getSelectedRows();
                    if (currentSelection.length > 0) {
                      $scope.tmpDetalle = currentSelection[0];
                      ngDialog.open({
                         template: 'app/agenda/agenda.editaDetalle.tpl.html',
                         className: 'ngdialog-theme-flat',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '70%',
                         height:'60%',
                         scope: $scope
                       });
                    } else {
                      toastr.error("Seleccione la fila a la que desea editar");
                    }
                  }

                  $scope.guardarCambiosDetalle = function() {
                    if ($scope.tmpDetalle.descripcion != null) {
                      for (var i = 0; i < $scope.detalles.length; i++) {
                        if ($scope.detalles[i].detalleSesionId == $scope.tmpDetalle.detalleSesionId) {
                          $scope.detalles[i].descripcion = $scope.tmpDetalle.descripcion;
                          break;
                        }
                      }
                      $scope.gridOptionsDetalle.data=$scope.detalles;
                      $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                      ngDialog.close();
                    }
                  }

                  $scope.expandAll = function(){
                    $scope.gridApi.treeBase.expanded=true;
                    $scope.gridApi.treeBase.expandAllRows();
                  };

                  $scope.collapseAll = function(){
                    $scope.gridApi.treeBase.expanded=false;
                    $scope.gridApi.treeBase.collapseAllRows();
                  };

                  $scope.toggleRow = function( row,evt ){
                    uiGridTreeBaseService.toggleRowTreeState($scope.gridApi.grid, row, evt);
                  };

                  $scope.toggleAll = function( row,evt ){
                    if($scope.gridApi.treeBase.expanded) {
                        $scope.collapseAll();
                    } else {
                        $scope.expandAll();
                    }
                  };

                  $scope.toggleExpandNoChildren = function(){
                    $scope.gridOptions.showTreeExpandNoChildren = !$scope.gridOptions.showTreeExpandNoChildren;
                    $scope.gridApi.grid.refresh();
                  };

                  $scope.getTableHeight = function() {
                    var rowHeight = 80; // your row height
                    var headerHeight = 80; // your header height
                    var rows = $scope.gridOptionsDetalle.data.length;
                    if (rows <= 0) {
                      rows = 1;
                    } else {
                      if (rows > appSettings.paginationPageSize) {
                        rows = appSettings.paginationPageSize;
                      }
                    }
                    return {
                      height: (rows * rowHeight + headerHeight) + "px"
                    };
                  };

                  $scope.findInArray2 = function(condicion, array) {
                      var res;
                       var found = $filter('filter')(array, condicion, true);
                       if (found.length){
                           res = found[0];
                       } else {
                           res   = 'Not found';
                       }
                       return res;
                   }
                  $scope.addComentario=function (row)
                  {

                    //$scope.gridOptionsAdjuntos.data=row.ttArchivoAdjuntos;
                    $scope.detalle=row;
                    var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                    if(res.length>0)
                    {
                      $scope.existComment=true;
                    }
                    else {
                      $scope.existComment=false;
                      //$scope.sesion.comentarioUsuarioId=res.comentarioUsuarioId;
                      $scope.comentarioUsuarioId=res.comentarioUsuarioId;
                    }
                    /*$scope.sesion.comentario=res.comentario;
                    $scope.sesion.detalleSesionId=row.detalleSesionId;
                    $scope.sesion.objcomentario=res;
                    $scope.sesion.comentarios=row.ttComentarioUsuario;*/
                    $scope.sesion.comentario=res.comentario;

                    $scope.detalleSesionId=row.detalleSesionId;
                    $scope.objcomentario=res;
                    $scope.comentarios=row.ttComentarioUsuario;

                    ngDialog.open({
                       template: 'app/agenda/agenda.comentario.tpl.html',
                       className: 'ngdialog-theme-flat',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '70%',
                       height:'60%',
                       scope: $scope
                     });
                  }
                  $scope.guardarComentario=function()
                  {
                    if($scope.sesion.comentario==null)

                    //if($scope.comentario==null)
                      toastr.error("Ingrese el texto del comentario.");
                    else
                    {
                      var a={
                        comentario:$scope.sesion.comentario,
                        //comentario:$scope.comentario,
                        //detalleSesionId:$scope.sesion.detalleSesionId,
                        detalleSesionId:$scope.detalleSesionId,
                        usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                        puntoVisto : 0,
                        puntoAprobado : 0
                      };
                      agendaService.addComentario(a).then( function ( result )
                      {
                        if(result.status=='OK')
                        {
                          //$scope.sesion.objcomentario.comentario=a.comentario;
                          a.comentarioUsuarioId=result.data[0].comentarioUsuarioId;
                          //$scope.sesion.comentarios.push(a);
                          $scope.comentarios.push(a);
                          toastr.success("Comentario almacenado con exito");
                          ngDialog.close();
                        }
                        else
                        {
                          toastr.error( "Error al almacenar el comentario:"+result.message );
                        }

                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }

                  }
                  $scope.actualizarComentario=function()
                  {
                    var a={
                      comentarioUsuarioId:$scope.comentarioUsuarioId,
                      comentario:$scope.sesion.comentario,
                      //comentario:$scope.comentario,
                      //detalleSesionId:$scope.sesion.detalleSesionId,
                      detalleSesionId:$scope.detalleSesionId,
                      usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                    };
                    agendaService.updComentario(a).then( function ( result )
                    {
                      if(result.status=='OK')
                      {
                        //$scope.sesion.objcomentario.comentario=a.comentario;
                        $scope.objcomentario.comentario=a.comentario;
                        toastr.success("Comentario actualizado con exito");
                      }
                      else
                      {
                        toastr.error( "Error al actualizar el comentario:"+result.message );
                      }

                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                  $scope.cerrarComentario=function()
                  {
                    ngDialog.close();
                  }
                  $scope.existeComentarioDetalle= function(row)
                  {
                    var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                    if(res.comentario!=null && res.comentario != "")
                    {
                      /*if (res.comentario == ".") {
                        return false;
                      }*/
                      return true;
                    }
                    else
                    {

                      return false;
                    }
                  }
                  $scope.esSecretario= function()
                  {
                    if(appSettings.perfilId!=5)
                      return false;
                    else {
                      return true;
                    }
                  }
                  /*************************funciones para construir el arbol******************/
                  $scope.findInArray = function(padreId, array)
                  {
                       var res;
                       var found = $filter('filter')(array, {detalleSesionId: padreId.detalleSesionId}, true);
                       if (found.length)
                       {
                           res = found[0];
                       }
                       else
                       {
                           res   = 'Not found';
                       }
                       return res;
                   }
                  $scope.tryPushDetalle=function(row,detalles)
                  {
                    if(row.ttDetallePadre==null || row.ttDetallePadre.detalleSesionId==null)
                    {
                      row.$$treeLevel=0;
                      row.hasChildren=false;
                      $scope.gridOptionsDetalle.data.push(row);
                      var index = detalles.indexOf(row);
                      detalles.splice( index , 1 );
                    }
                  }
                  $scope.addDetalle=function(row, array)
                  {
                    var found=$scope.findInArray(row.ttDetallePadre,$scope.gridOptionsDetalle.data);
                    var index= $scope.gridOptionsDetalle.data.indexOf(found);
                    if(index!=-1)
                    {
                      row.$$treeLevel=found.$$treeLevel+1;
                      found.hasChildren=true;
                      $scope.gridOptionsDetalle.data.splice(index+1,0,row);
                      //array.splice(index+1,0,row);
                      index=array.indexOf(row);
                      array.splice(index,1);
                    }

                  };

                 $scope.buildTree=function(detalles)
                 {
                   var newArray=detalles.slice();
                   for(detalle in detalles)
                   {
                     detalles[detalle].saved=true;
                     $scope.tryPushDetalle(detalles[detalle],newArray);
                   }
                   detalles=newArray.slice();
                   do
                   {
                     detalles.sort(function(a, b) {
                        return b.numeracion.localeCompare(a.numeracion);
                      });
                     for(detalle in detalles)
                     {
                       detalles[detalle].saved=true;
                       $scope.addDetalle(detalles[detalle],newArray);
                     }
                     detalles=newArray.slice();

                   } while (detalles.length>0);


                    $scope.setAllowAdjuntos($scope.gridOptionsDetalle.data);
                 }

                 /***********************fin funciones para construir el arbol****************/
                 $scope.inicializar=function()
                 {
                   $scope.detalles=$scope.sesion.ttDetalleSesions;
                   //var auxDetalles=$scope.detalles.slice();
                   $scope.detalles.sort(function(a, b) {
                      return parseFloat(a.numeracion) - parseFloat(b.numeracion);
                    });
                   $scope.buildTree($scope.detalles);
                   //$scope.detalles=auxDetalles;
                   $scope.detalles=$scope.gridOptionsDetalle.data;
                 }
                 $rootScope.setChangeListener($scope,'sesion');

                  $scope.inicializar();
                  $scope.renumerar = function()
                  {
                    var numeracion=1;
                    var pilaNumeracion=[];
                    var detalles=$scope.gridOptionsDetalle.data;
                    for (d in detalles)
                    {
                      if(detalles[d].$$treeLevel==null||detalles[d].$$treeLevel==0)
                      {
                        //no tiene padre, nivel 0
                        detalles[d].numeracion=numeracion;
                        if(pilaNumeracion.length==1)
                        {
                          pilaNumeracion[0]=numeracion;
                        }
                        else
                        {
                          pilaNumeracion.push(numeracion);
                        }
                        numeracion++;
                      }
                      else
                      {
                        var padre=$scope.findInArray(detalles[d].ttDetallePadre,detalles);
                        var diferenciaNivelAnterior=(detalles[d].$$treeLevel-detalles[d-1].$$treeLevel);
                        if(diferenciaNivelAnterior==1)
                        {
                          detalles[d].numeracion=padre.numeracion+'.'+1;
                          pilaNumeracion.push(1);
                        }
                        else if(diferenciaNivelAnterior<0)
                        {
                            for(i=0;i<(-1)*diferenciaNivelAnterior;i++)
                            {
                              pilaNumeracion.pop();
                            }
                            detalles[d].numeracion=padre.numeracion+'.'+(pilaNumeracion[pilaNumeracion.length-1]+1);
                            pilaNumeracion[pilaNumeracion.length-1]++;
                        }
                        else if(diferenciaNivelAnterior==0)
                        {
                          detalles[d].numeracion=padre.numeracion+'.'+(pilaNumeracion[pilaNumeracion.length-1]+1);
                          pilaNumeracion[pilaNumeracion.length-1]++;
                        }
                      }
                    }

                  }
                  $scope.addDetalle = function(row) //agregar detalle o fila al grid
                  {

                    agendaService.getDetalleSesionId().then( function ( result )
                    {
                      if(result.status='OK') {
                        /*3 opcones de agregar
                          1-Agregar al final(row=null y no fila seleccionada)
                          2-Agregar posterior a la fila seleccionada(row=null y existe fila seleccionada)
                          3-Agregar como subitem(row trae fila)
                        */
                        var filaSeleccionada;
                        var treeLevel;
                        var padre;

                        var i = {
                          detalleSesionId:result.data[0].secuencia,
                          //ttDetallePadre:padre.detalleSesionId==null?null:padre,
                          usuarioCreaId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                          //numeracion : strNumeracion,
                          ttComentarioUsuario:[],
                          saved:false
                        };

                        i.$$treeLevel = treeLevel;
                        if (row == null)
                        {
                          var currentSelection = $scope.gridApi.selection.getSelectedRows();
                          if (currentSelection.length > 0)
                          {
                            //2-
                            filaSeleccionada = currentSelection[0];
                            var index=$scope.gridOptionsDetalle.data.indexOf(filaSeleccionada);
                            i.ttDetallePadre=filaSeleccionada.ttDetallePadre;
                            i.$$treeLevel=filaSeleccionada.$$treeLevel;

                            var tempLevel=filaSeleccionada.$$treeLevel;
                            index++;
                            while(index<$scope.gridOptionsDetalle.data.length && tempLevel!=$scope.gridOptionsDetalle.data[index].$$treeLevel)
                            {
                              index++;
                            }
                            $scope.gridOptionsDetalle.data.splice(index,0,i);
                          }
                          else
                          {
                            //1-
                            i.ttDetallePadre=null;
                            i.$$treeLevel=0;
                            $scope.gridOptionsDetalle.data.push(i);
                          }
                        }
                        else
                        {
                          //3-
                          i.ttDetallePadre=row;
                          i.$$treeLevel=row.$$treeLevel+1;
                          var index=$scope.gridOptionsDetalle.data.indexOf(row)+1;
                          var tempLevel=row.$$treeLevel;
                          while(index<$scope.gridOptionsDetalle.data.length && tempLevel<$scope.gridOptionsDetalle.data[index].$$treeLevel)
                          {
                            index++;
                          }
                          $scope.gridOptionsDetalle.data.splice(index,0,i);
                          row.hasChildren=true;
                        }


                        $scope.gridOptionsDetalle.columnDefs[0].width = i.$$treeLevel * 20;


                        //$scope.gridOptionsDetalle.data=$scope.detalles;
                        $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                        $scope.renumerar();
                      } else {
                        toastr.error( result.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                  $scope.deleteDetalle = function(row)
                  {
                    var index = $scope.gridOptionsDetalle.data.indexOf(row);
                    var nivel=row.$$treeLevel;
                    do
                    {
                      $scope.gridOptionsDetalle.data.splice(index,1);
                      //index++;
                    }
                    while(index<$scope.gridOptionsDetalle.data.length && $scope.gridOptionsDetalle.data[index].$$treeLevel!=nivel);

                    $scope.detalles=$scope.gridOptionsDetalle.data;
                    $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                    $scope.renumerar();
                  }
                  $scope.addAdjuntos=function (row)
                  {
                    var index = $scope.gridOptionsDetalle.data.indexOf(row);
                    $scope.index=index;
                    $scope.detalle=row;
                    $scope.gridOptionsAdjuntos.data=$scope.detalle.ttArchivoAdjuntos;
                    ngDialog.open({
                       template: 'app/agenda/agenda.addadjuntos.tpl.html',
                       //className: 'ngdialog-theme-default',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '70%',
                       height:600,
                       //showClose: false,
                       scope: $scope
                     });
                  }
                  $scope.cerrarAdjuntos=function()
                  {
                    ngDialog.close();
                  }
                  /***************************************************************/
                  $scope.deleteAdjunto = function( row ) {

                    //$scope.sesion.ttArchivoAdjuntos.push(response.data[0]);
                    //$scope.gridOptionsAdjuntos.data=$scope.sesion.ttArchivoAdjuntos;
                      var index = $scope.detalle.ttArchivoAdjuntos.indexOf(row);
                      $scope.detalle.ttArchivoAdjuntos.splice( index , 1 );
                      //$scope.gridOptionsAdjuntos.data=$scope.sesion.ttArchivoAdjuntos;

                  }
                  $scope.addFile = function( isValid ){
                    if ( isValid ) {
                      var file = $scope.$$childTail.myFile2;
                      if(file == null || file.name == null) {
                        toastr.error("Debe elegir un archivo");
                        return;
                      }

                      if($scope.archivo == null || $scope.archivo.descripcion == null) {
                        toastr.error("Ingrese la descripción del archivo");
                        return;
                      }

                      agendaService.uploadFile(file).then( function ( response ) {
                        toastr.success( "Archivo cargado correctamente." );
                        response.data[0].descripcion=$scope.archivo.descripcion;
                        response.data[0].folios=$scope.archivo.folios;
                        response.data[0].temp=true;
                        response.data[0].usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        var adjuntos= $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos;
                        if(adjuntos ==null)
                        {
                            $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos=[];
                        }
                        $scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos.push(response.data[0])
                        $scope.gridOptionsAdjuntos.data=$scope.sesion.ttDetalleSesions[$scope.index].ttArchivoAdjuntos;
                        $scope.$$childTail.myFile2={};
                        $scope.archivo.descripcion=null;
                        $scope.archivo.folios=0;
                      }, function ( error ) {
                        toastr.error( error );
                      });
                      $scope.sesion.ttDetalleSesions=$scope.gridOptionsDetalle.data;
                    }
                  };
                  $scope.deleteSesion=function()
                  {
                    swal({
                      title: "¿Está seguro que desea eliminar la sesión?",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    },
                    function (isValid) {
                      if ( isValid ) {


                        agendaService.delete( $scope.sesion.sesionId ).then( function ( response ) {
                          if(response.status=="OK")
                          {
                            $rootScope.setExistChanges(false);
                            toastr.success( response.message );
                            $state.go("index.agenda.listadmin")
                          }
                          else {
                              toastr.error( response.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }

                    });
                  }
                  $scope.finalizarSesion_bk= function ()
                  {
                    swal({
                      title: "¿Está seguro que desea finalizar la sesión? Ya no podrá hacer modificaciones y se enviará la alerta a los participantes.",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    },
                    function (isValid) {
                      if ( isValid ) {
                        var aux={
                          sesionId:$scope.sesion.sesionId,
                          contactos:[]
                        };


                        agendaService.finalizarSesion( aux ).then( function ( response ) {
                          if(response.status=="OK")
                          {
                            toastr.success( response.message );
                            $scope.sesion={};
                            $state.go("index.agenda.listadmin")
                          }
                          else {
                              toastr.error( response.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }

                    });
                  }
                  $scope.reporteComentarios=function() {
                    window.open(appSettings.reportingServicesUri + 'Reporte.aspx?p=/AGENDAPLENO/AP_COMENTARIOS_SESION&P_SESION_ID='+$scope.sesion.sesionId+'&P_USUARIO_ID='+authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId+'&P_USUARIO='+authService.getLocalData('loginData'+ appSettings.sistemaId).usuario,'Reporte', 'width=800, height=600' );
                  }

                  $scope.reporteComentariosCompartidos=function() {
                    window.open(appSettings.reportingServicesUri + 'Reporte.aspx?p=/AGENDAPLENO/AP_COMENTARIOS_COMPARTIDOS&P_SESION_ID='+$scope.sesion.sesionId+'&P_USUARIO_ID='+authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,'ReporteCompartido', 'width=800, height=600' );
                  }

                  $scope.reportePuntosAprobados = function() {
                    window.open(appSettings.reportingServicesUri + 'Reporte.aspx?p=/AGENDAPLENO/rptAprobados&P_SESION_ID=' + $scope.sesion.sesionId + '&P_USUARIO=' + authService.getLocalData('loginData'+ appSettings.sistemaId).usuario,'ReporteAprobado', 'width=800, height=600' );
                  }
                  $scope.esVisto = function (row) {
                    var res = $scope.findInArray2(
                      {
                        usuarioId : authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                      }, row.ttComentarioUsuario
                    );
                    if (res.comentarioUsuarioId != null) {
                      if (res.puntoVisto == 1) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }

                  $scope.estaAprobado = function (row) {
                    var res = $scope.findInArray2(
                      {
                        usuarioId : authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                      }, row.ttComentarioUsuario
                    );
                    if (res.puntoAprobado != null) {
                      if (res.puntoAprobado == 1) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }
                  $scope.aprobarPunto = function ( row ) {
                    var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                    var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                    var texto = "marcar como aprobado";
                    if (res.comentarioUsuarioId != null) {
                      if (res.puntoAprobado == 1) {
                        texto = "desmarcar como aprobado";
                      }
                    }
                    swal({
                      title: "¿Está seguro que desea " + texto + " este punto?",
                      text: "",
                      showCancelButton: true,
                      confirmButtonClass: "btn btn-success",
                      confirmButtonText: "Confirmar",
                      cancelButtonClass: "btn btn-danger",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                    }, function (isValid) {
                      if ( isValid ) {
                        var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                        if (res.comentarioUsuarioId != null) {
                          if (res.puntoAprobado == 1) {
                            res.puntoAprobado = 0;
                          } else {
                            res.puntoAprobado = 1;
                          }
                          agendaService.updComentario(res).then( function ( result ) {
                            if(result.status=='OK') {
                              toastr.success("Punto aprobado exitosamente");
                            } else {
                              toastr.error( "Error al aprobar el punto: " + result.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        } else {
                          var res = {
                            //comentario : ".",
                            comentarioUsuarioId: 0,
                            detalleSesionId : row.detalleSesionId,
                            puntoAprobado : 1,
                            puntoVisto : 0,
                            usuarioId : usuarioId
                          }
                          agendaService.addComentario(res).then( function ( result ) {
                            if(result.status=='OK') {
                              row.ttComentarioUsuario.push(result.data[0]);
                              toastr.success("Punto aprobado exitosamente");
                            } else {
                              toastr.error( "Error al aprobar el punto: " + result.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }
                      }
                    });
                  }

                  $scope.marcarVisto = function ( row ) {
                    var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                    var res = $scope.findInArray2(
                      {
                        usuarioId : usuarioId
                      }, row.ttComentarioUsuario
                    );
                    if (res.comentarioUsuarioId != null) {
                      var index = $scope.gridOptionsDetalle.data.indexOf(row);
                      if (res.puntoVisto == 1) {
                        res.puntoVisto = 0;
                      } else {
                        res.puntoVisto = 1;
                      }
                      agendaService.updComentario(res).then( function ( result ) {
                        if(result.status=='OK') {
                          toastr.success(result.message);
                        } else {
                          toastr.error( "Error: " + result.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    } else {
                      var res = {
                        //comentario : ".",
                        comentarioUsuarioId: 0,
                        detalleSesionId : row.detalleSesionId,
                        puntoAprobado : 0,
                        puntoVisto : 1,
                        usuarioId : usuarioId
                      }
                      agendaService.addComentario(res).then( function ( result ) {
                        if(result.status=='OK') {
                          row.ttComentarioUsuario.push(result.data[0]);
                          toastr.success("Punto marcado como visto exitosamente");
                        } else {
                          toastr.error( "Error al marcar como visto el punto: " + result.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  }
                  $scope.limpiarComentario = function () {
                    $scope.sesion.comentario = "";
                    $scope.actualizarComentario();
                  }
                  $scope.vistaPrevia = function () {
                    //window.open( 'http://reporteria.oj.gob.gt/Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId);
                    var urlReporteAgenda;
                    if (appSettings.profile == 3) {
                      urlReporteAgenda = appSettings.restApiServiceBaseUri + "sesion/reporte/" + $scope.sesion.sesionId;
                    } else {
                      urlReporteAgenda = appSettings.reportingServicesUri +'Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId;//+'&p_base='+ appSettings.restApiServiceBaseUri;
                    }
                    //$scope.sesion.path2=encodeURIComponent(urlReporteAgenda)
                    urlReporteAgenda='./web/viewer.html?file='+encodeURIComponent(urlReporteAgenda);

                    $scope.trustSrc = function(src) {
                      return $sce.trustAsResourceUrl(src);
                    }
                    $scope.iframe = {src:urlReporteAgenda};
                    ngDialog.open({
                       template: 'app/agenda/agenda.viewdetalle.tpl.html',
                       //className: 'ngDialog-custom-width',
                       //appendClassName: 'ngdialog-custom',
                       closeByDocument: true,
                       closeByEscape: true,
                       width: '95%',
                       height:'100%',
                       scope: $scope
                     });
                  }

                  $scope.vistaPrevia();

                  /*
                  funcion para manejar los cambios
                  */

                  /************************************************************************************************************/
                } //end
              ]
            }
          }
        })
        .state( 'index.agenda.view', {
          url: '/:id/view',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.view.tpl.html',
              resolve: {
                permisoEstado : ['authService',
                  function( authService){
                    return authService.loginPermission(2);
                  }],
                sesion: ['agendaService', '$stateParams',
                  function( agendaService, $stateParams){
                    $dataSesion = agendaService.get( $stateParams.id);//usuarioId=1
                    return $dataSesion;
                  }]
              },
              controller: ['$scope', '$rootScope','$state', 'toastr', 'agendaService','sesion','$sce','ngDialog','uiGridConstants','$stateParams','$filter','authService','uiGridTreeBaseService', '$base64','localStorageService',
                function (  $scope, $rootScope,  $state,   toastr,   agendaService,sesion,$sce,ngDialog,uiGridConstants,$stateParams,$filter,authService,uiGridTreeBaseService,$base64,localStorageService)
                {


                    $scope.existComment=false;
                    $scope.mostrarCompartido = 1;
                    //$scope.tiposVoto=tiposVoto.data;//catalogo de tipos de votos
                    $scope.voto={};
                    $scope.voto.observaciones;//={};
                    $scope.voto.tcTipoVoto;
                    //$scope.setTabs(2,$stateParams.id);//setea los tabs

                    $scope.setTabs(2,$stateParams.id);//setea los tabs
                    if(sesion==null||sesion.data==null||sesion.data[0]==null)
                    {
                      toastr.error("Error al obtener los datos: " +sesion.message);
                    }
                    else
                    {
                      sesion.data[0].sesion=sesion.data[0].anio+"-"+sesion.data[0].correlativo;
                      sesion.data[0].tipo=sesion.data[0].tipoSesion==1?"Ordinaria":"Extraordinaria"

                      $scope.sesion=sesion.data[0]
                      //console.log('url:'+sesion.data[0].path );
                      var urlReporteAgenda;
                      urlReporteAgenda=appSettings.reportingServicesUri +'Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId;//+'&p_base='+ appSettings.restApiServiceBaseUri;

                      $scope.sesion.path2=encodeURIComponent(urlReporteAgenda)

                      urlReporteAgenda='http://10.101.8.210:8085/agendapleno/web/viewer.html?file='+$scope.sesion.path2;

                      $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                      $scope.iframe = {src:urlReporteAgenda};

                      $scope.gridOptionsAdjuntos = angular.copy( $scope.gridOptionsSelection ); //grid adjuntos view
                      $scope.gridOptionsAdjuntos.enableFiltering= true;
                      $scope.gridOptionsAdjuntos.columnDefs = [
                        { name: 'folios', displayName: "Folios", width:"10%", enableFiltering: false  },
                        { name: 'archivoAdjuntoId', displayName: "Id", width: "12%",enableFiltering: true, visible:false, sort: { direction: uiGridConstants.ASC }},
                        { name: 'descripcion', displayName: "Descripción", width: "80%",enableFiltering: true, },
                        //{ name: 'pathArchivo', displayName: "Archivo", width: "50%" },
                        { name: 'Ver', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-default-active btn-xs text-green" ng-click="grid.appScope.verAdjunto(row.entity)" title="Ver"><i class="zmdi zmdi-download"></i></button></span></div>',
                      width:'10%' },
                      ];
                      $scope.getTableHeight = function() {
                        var rowHeight = 80; // your row height
                        var headerHeight = 80; // your header height
                        var rows = $scope.gridOptionsAdjuntos.data.length;
                        if (rows <= 0) {
                          rows = 1;
                        } else {
                          if (rows > appSettings.paginationPageSize) {
                            rows = appSettings.paginationPageSize;
                          }
                        }
                        return {
                          height: (rows * rowHeight + headerHeight) + "px"
                        };
                      };
                      $scope.gridOptionsAdjuntos.data=$scope.sesion.ttArchivoAdjuntos;


                    }
                    $scope.isHistoric=function()
                    {
                      var res=false;
                      var date1=new Date($scope.sesion.fechaHora);
                      var date2=new Date();
                      date2.setHours(0,0,0,0);
                      if(date1<date2)
                      {
                        return true;
                      }
                      return res;
                    }
                    $scope.verAdjunto=function(row)
                    {
                      var link = angular.element('<a href="' + appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId + '" target="_blank"></a>');
                      angular.element(document.body).append(link);
                      link[0].click();
                      link.remove();
                      //window.open(appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId);
                    }
                    /*Mostrar dialogo de archivos adjuntos*/
                    $scope.mostrarAdjuntos=function ()
                    {
                      ngDialog.open({
                         template: 'app/agenda/agenda.adjuntos.tpl.html',
                         className: 'ngdialog-theme-default',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '500',
                         height:'50%',
                         scope: $scope
                       });
                    }
                    $scope.cerrarAdjuntos=function() {
                      ngDialog.close();
                    }
                    $scope.findInArray = function(padreId, array)
                    {
                         var res;
                         var found = $filter('filter')(array, {detalleSesionId: padreId.detalleSesionId}, true);
                         if (found.length) {
                             res = found[0];
                         } else {
                             res   = 'Not found';
                         }
                         return res;
                     }
                    $scope.tryPushDetalle=function(row,detalles)
                    {
                      if(row.ttDetallePadre==null || row.ttDetallePadre.detalleSesionId==null)
                      {
                        row.$$treeLevel=0;
                        $scope.gridOptionsDetalle.data.push(row);
                        var index = detalles.indexOf(row);
                        detalles.splice( index , 1 );
                      }
                    }
                    $scope.addDetalle=function(row, array)
                    {
                      var found=$scope.findInArray(row.ttDetallePadre,$scope.gridOptionsDetalle.data);
                      var index= $scope.gridOptionsDetalle.data.indexOf(found);
                      if(index!=-1)
                      {
                        row.$$treeLevel=found.$$treeLevel+1;
                        $scope.gridOptionsDetalle.data.splice(index+1,0,row);
                        found.hasChildren=true;
                        //array.splice(index+1,0,row);
                        index=array.indexOf(row);
                        array.splice(index,1);
                      }
                    };

                     $scope.buildTree=function(detalles)
                     {
                       var newArray=detalles.slice();
                       for(detalle in detalles)
                       {
                         $scope.tryPushDetalle(detalles[detalle],newArray);
                       }
                       detalles=newArray.slice();
                       do
                       {
                         detalles.sort(function(a, b) {
                            return b.numeracion.localeCompare(a.numeracion);
                          });
                         for(detalle in detalles)
                         {
                           $scope.addDetalle(detalles[detalle],newArray);
                         }
                         detalles=newArray.slice();
                       } while (detalles.length>0);


                     }

                    if(sesion==null||sesion.data==null||sesion.data[0]==null)
                    {
                      toastr.error("Error al obtener los datos: " +sesion.message);
                    }
                    else
                    {
                      $scope.sesion=sesion.data[0]
                      $scope.detalles=$scope.sesion.ttDetalleSesions;

                      //$scope.sesion={};
                      $scope.gridOptionsDetalle = angular.copy( $scope.gridOptionsSelection);
                      $scope.gridOptionsDetalle.showTreeExpandNoChildren= true;
                      $scope.gridOptionsDetalle.showTreeRowHeader= false;
                      $scope.gridOptionsDetalle.enableColumnResizing=true;
                      $scope.gridOptionsDetalle.enableFiltering = true;

                      $scope.gridOptionsDetalle.columnDefs = [ //grid magistrado
                        { name: 'tree', width:'8%', enableCellEdit:false,
                          headerCellTemplate:'<div class="ui-grid-cell-contents text-center col-options"><span><div '
                            +'class=" text-white" ng-click="grid.appScope.toggleAll()" title="Expandir/colapsar">'
                            +'<i ng-if="grid.appScope.gridApi.treeBase.expanded" class="ui-grid-icon-minus-squared" ></i>'
                            +'<i ng-if="!grid.appScope.gridApi.treeBase.expanded" class="ui-grid-icon-plus-squared" ></i>'
                            +'</button></span><div>', enableFiltering : false,enableSorting:false,
                          cellTemplate : '<div class="ui-grid-cell-contents" title="Expandir" ><div style="float:left;" '
                          +'class="ui-grid-tree-base-row-header-buttons" ng-class="{\'ui-grid-tree-base-header\': row.treeLevel > -1 }" '
                          +'ng-click="grid.appScope.toggleRow(row,evt)"><i ng-if="row.entity.hasChildren" '
                          +'ng-class="{\'ui-grid-icon-minus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || '
                          +'( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'expanded\','
                          +' \'ui-grid-icon-plus-squared\': ( ( grid.options.showTreeExpandNoChildren && row.treeLevel > -1 ) || '
                          +'( row.treeNode.children && row.treeNode.children.length > 0 ) ) && row.treeNode.state === \'collapsed\'}" '
                          +'ng-style="{\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}"></i> &nbsp;</div></div>' },
                        { name: 'numeracion', headerCellTemplate:'<div class="text-center"><h4><b>No.</b></h4></div>',
                          enableSorting : false, displayName: "No.", enableFiltering : false, width:'130',
                          cellTemplate : '<div class="ui-grid-cell-contents" > ' +
                          '<div style="float:left;" ' +
                          'ng-style="{\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}">{{row.entity.numeracion}}</div>'
                        },
                        { name: 'descripcion', displayName: "Descripción",headerCellClass: 'blue' ,headerCellTemplate:'<div><h4><b>Descripción</b></h4></div>', },
                        { name: 'OPCIONES', enableFiltering: false,headerCellClass: 'blue' ,headerCellTemplate:'<div><h4><b>Opciones</b></h4></div>',
                          cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                          +'<button type="button" ng-if="row.entity.ttArchivoAdjuntos.length>0" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.mostrarAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black"></i><span class="label label-danger span">1</span></button>'
                          +'<button type="button" ng-if="row.entity.ttArchivoAdjuntos.length==0" class="btn btn-default btn-xs text-yellow" ng-click="grid.appScope.mostrarAdjuntos(row.entity)" title="Archivos Adjuntos"><i class="zmdi zmdi-attachment-alt text-black"></i><span class="label text-center span">..</span></button>'
                          +'<button type="button" ng-if="grid.appScope.existeComentarioDetalle(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label label-danger text-center span">1</span></button>'
                          +'<button type="button" ng-if="!grid.appScope.existeComentarioDetalle(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-green" ng-click="grid.appScope.addComentario(row.entity)" title="Agregar comentario"><i class="fa fa-commenting"></i><span class="label text-center span">..</span></button>'
                          +'<button type="button" ng-if="grid.appScope.esVisto(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.marcarVisto(row.entity)" title="Punto marcado como visto"><i class="zmdi zmdi-eye"></i><span class="label label-danger text-center span"><i class="zmdi zmdi-check"></i></span></button>'
                          +'<button type="button" ng-if="!grid.appScope.esVisto(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-yellow" ng-click="grid.appScope.marcarVisto(row.entity)" title="Marcar como punto visto"><i class="zmdi zmdi-eye-off"></i><span class="label text-center span">..</span></button></button>'
                          +'<button type="button" ng-if="grid.appScope.estaAprobado(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-blue" ng-click="grid.appScope.aprobarPunto(row.entity)" title="Voto realizado"><i class="zmdi zmdi-check-all"></i><span class="label label-danger text-center"></span></button>'
                          +'<button type="button" ng-if="!grid.appScope.estaAprobado(row.entity)" class="btn btn-default btn-xs dropdown-toggle text-black" ng-click="grid.appScope.aprobarPunto(row.entity)" title="Realizar Voto"><i class="fa fa-check"></i><span class="label text-center span"></span></button></span></div>',
                        width:'30%' },

                      ];

                      $scope.gridOptionsDetalle.onRegisterApi= function( gridApi ) {
                        $scope.gridApi = gridApi;
                        gridApi.selection.on.rowSelectionChanged($scope,function(row){
                          if (row.isSelected) {
                            $scope.mostrarOpciones = 1;
                          } else {
                            $scope.mostrarOpciones = 0;
                          }
                        });
                        gridApi.selection.on.rowSelectionChangedBatch($scope,function(rows){

                        });
                      }

                      $scope.expandAll = function(){
                        $scope.gridApi.treeBase.expanded=true;
                        $scope.gridApi.treeBase.expandAllRows();
                      };
                      $scope.collapseAll = function(){
                        $scope.gridApi.treeBase.expanded=false;
                        $scope.gridApi.treeBase.collapseAllRows();
                      };
                      $scope.toggleRow = function( row,evt ){
                        uiGridTreeBaseService.toggleRowTreeState($scope.gridApi.grid, row, evt);
                        //$scope.gridApi.treeBase.toggleRowTreeState($scope.gridApi.grid.renderContainers.body.visibleRowCache[rowNum]);
                      };
                      $scope.toggleAll = function( row,evt ) {
                        if($scope.gridApi.treeBase.expanded) {
                            $scope.collapseAll();
                        } else {
                          $scope.expandAll();
                        }
                      };

                      $scope.esVisto = function (row) {
                        var res = $scope.findInArray2(
                          {
                            usuarioId : authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                          }, row.ttComentarioUsuario
                        );
                        if (res.comentarioUsuarioId != null) {
                          if (res.puntoVisto == 1) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      }

                      $scope.estaAprobado = function (row) {
                        var res = $scope.findInArray2(
                          {
                            usuarioId : authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId
                          }, row.ttComentarioUsuario
                        );
                        if (res.puntoAprobado != null) {
                          if (res.puntoAprobado == 1) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      }
                      $scope.estaAprobado2 = function (row) {

                        if (row.ttVotos.length>0)
                        {
                          return true;
                        }
                        else
                        {
                          return false;
                        }
                      }
                      $scope.aprobarPunto = function ( row ) {
                        var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                        var texto = "marcar como aprobado";
                        if (res.comentarioUsuarioId != null) {
                          if (res.puntoAprobado == 1) {
                            texto = "desmarcar como aprobado";
                          }
                        }
                        swal({
                          title: "¿Está seguro que desea " + texto + " este punto?",
                          text: "",
                          showCancelButton: true,
                          confirmButtonClass: "btn btn-success",
                          confirmButtonText: "Confirmar",
                          cancelButtonClass: "btn btn-danger",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                        }, function (isValid) {
                          if ( isValid ) {
                            var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                            var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                            if (res.comentarioUsuarioId != null) {
                              if (res.puntoAprobado == 1) {
                                res.puntoAprobado = 0;
                              } else {
                                res.puntoAprobado = 1;
                              }
                              agendaService.updComentario(res).then( function ( result ) {
                                if(result.status=='OK') {
                                  toastr.success("Punto aprobado exitosamente");
                                } else {
                                  toastr.error( "Error al aprobar el punto: " + result.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            } else {
                              var res = {
                                //comentario : ".",
                                comentarioUsuarioId: 0,
                                detalleSesionId : row.detalleSesionId,
                                puntoAprobado : 1,
                                puntoVisto : 0,
                                usuarioId : usuarioId
                              }
                              agendaService.addComentario(res).then( function ( result ) {
                                if(result.status=='OK') {
                                  row.ttComentarioUsuario.push(result.data[0]);
                                  toastr.success("Punto aprobado exitosamente");
                                } else {
                                  toastr.error( "Error al aprobar el punto: " + result.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          }
                        });
                      }

                      $scope.aprobarPunto2 = function ( row ) {
                        $scope.detalleSesionId=row.detalleSesionId;
                        $scope.detalleSesion=row;
                        if(row.ttVotos.length>0)
                          $scope.voto=row.ttVotos[0];
                        else
                        {
                            $scope.voto={};
                        }
                        ngDialog.open({
                           template: 'app/agenda/agenda.votacion.tpl.html',
                           //className: 'ngdialog-theme-default',
                           closeByDocument: true,
                           closeByEscape: true,
                           width: '300',
                           height:'50%',
                           scope: $scope
                         });


                        var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                        var texto = "marcar como aprobado";
                        if (res.comentarioUsuarioId != null) {
                          if (res.puntoAprobado == 1) {
                            texto = "desmarcar como aprobado";
                          }
                        }
                        /*swal({
                          title: "¿Está seguro que desea " + texto + " este punto?",
                          text: "",
                          showCancelButton: true,
                          confirmButtonClass: "btn btn-success",
                          confirmButtonText: "Confirmar",
                          cancelButtonClass: "btn btn-danger",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                        }, function (isValid) {
                          if ( isValid ) {
                            var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                            var res = $scope.findInArray2({usuarioId : usuarioId}, row.ttComentarioUsuario);
                            if (res.comentarioUsuarioId != null) {
                              if (res.puntoAprobado == 1) {
                                res.puntoAprobado = 0;
                              } else {
                                res.puntoAprobado = 1;
                              }
                              agendaService.updComentario(res).then( function ( result ) {
                                if(result.status=='OK') {
                                  toastr.success("Punto aprobado exitosamente");
                                } else {
                                  toastr.error( "Error al aprobar el punto: " + result.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            } else {
                              var res = {
                                //comentario : ".",
                                comentarioUsuarioId: 0,
                                detalleSesionId : row.detalleSesionId,
                                puntoAprobado : 1,
                                puntoVisto : 0,
                                usuarioId : usuarioId
                              }
                              agendaService.addComentario(res).then( function ( result ) {
                                if(result.status=='OK') {
                                  row.ttComentarioUsuario.push(result.data[0]);
                                  toastr.success("Punto aprobado exitosamente");
                                } else {
                                  toastr.error( "Error al aprobar el punto: " + result.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          }
                        });*/
                      }
                      $scope.mostrarVotacion = function (  )
                      {
                        swal({
                          title: "Ingrese su PIN de firma electrónica:",
                          text: "",
                          type: 'input',
                          inputType: 'password',
                          showCancelButton: true,
                          confirmButtonClass: "btn btn-success",
                          confirmButtonText: "Confirmar",
                          cancelButtonClass: "btn btn-danger",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: false,
                          closeOnCancel: true,
                          //showLoaderOnConfirm: true,
                          customClass: "warning"
                        }, function (typedPassword) {
                          if(typedPassword===false)
                          {
                            return false;
                          }
                          else if ( typedPassword==="" ) {
                            swal.showInputError("Ingrese pin!");

                          }
                          else
                          {
                            var data=
                              {
                                usuario:authService.getLocalData('loginData'+ appSettings.sistemaId).usuario,
                                contrasenia:$base64.encode(typedPassword),
                                cadena: '2017-01',
                                sistema: 29
                              };

                            agendaService.validaPin(data).then( function ( result ) {
                              if(result.status=='OK')
                              {
                                var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                                $scope.voto.usuarioId=usuarioId;
                                $scope.voto.ttDetalleSesion=
                                {
                                  detalleSesionId:$scope.detalleSesionId
                                }
                                $scope.voto.detalleSesionId=$scope.detalleSesionId;
                                if($scope.voto.votoId!=null)//Si el voto existe actualizar
                                {
                                  agendaService.updVoto($scope.voto.votoId,$scope.voto).then( function ( result ) {
                                    if(result.status=='OK')
                                    {
                                        swal("", "Voto actualizado con exito", "success");
                                        //$scope.detalleSesion.ttVotos.push($scope.voto);
                                        //$scope.voto.votoId=result.data[0].votoId;
                                        $scope.detalleSesion.ttVotos[0].votoId=result.data[0].votoId;
                                        //$scope.voto={};
                                        ngDialog.close();
                                    }
                                    else
                                    {
                                        toastr.error( result.message );
                                    }
                                  }, function ( error ) {
                                    toastr.error( error );
                                  });
                                }
                                else //Si el voto no existe crear
                                {
                                  agendaService.addVoto($scope.voto).then( function ( result ) {
                                    if(result.status=='OK')
                                    {
                                        swal("", "Voto realizado con exito", "success");
                                        $scope.detalleSesion.ttVotos.push(result.data[0]); //insertar el voto recien creado en el arreglo de votos del detalle de sesion
                                        $scope.voto={};
                                        ngDialog.close();
                                    }
                                    else
                                    {
                                        toastr.error( result.message );
                                    }
                                  }, function ( error ) {
                                    toastr.error( error );
                                  });
                                }
                              }
                              else
                              {
                                  swal.showInputError("Pin incorrecto!");
                              }
                            }, function ( error ) {
                              toastr.error( error );

                            });
                                                      }
                        });
                      }
                      $scope.submitForm=function(isValid)
                      {
                        if(isValid)
                          $scope.mostrarVotacion();
                      }

                      $scope.verificaPin = function(pin)
                      {

                        var data=
                          {
                            usuario:authService.getLocalData('loginData'+ appSettings.sistemaId).usuario,
                            contrasenia:$base64.encode(pin),
                            cadena: '2017-01',
                            sistema: 29
                          };

                        agendaService.validaPin(data).then( function ( result ) {
                          if(result.status=='OK')
                          {
                              return true;
                          }
                          else
                          {
                              return false;
                          }
                        }, function ( error ) {
                          toastr.error( error );
                          return false;
                        });
                      }
                      $scope.cerrarVotacion = function ()
                      {
                        ngDialog.close();
                      }
                      $scope.eliminarVotacion = function (data)
                      {
                        swal({
                          title: "¿Está seguro que desea eliminar la votación?",
                          text: "",
                          showCancelButton: true,
                          confirmButtonClass: "btn btn-success",
                          confirmButtonText: "Confirmar",
                          cancelButtonClass: "btn btn-danger",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                        },
                        function (isValid) {
                          if ( isValid ) {
                            agendaService.deleteVoto($scope.voto.votoId).then( function ( result ) {
                              if(result.status=='OK')
                              {
                                //eliminacion correcata
                                var index = $scope.detalleSesion.ttVotos.indexOf($scope.voto);
                                $scope.detalleSesion.ttVotos.splice( index , 1 );
                                ngDialog.close();
                                swal("", "Votación eliminada con exito", "success");
                              }
                              else
                              {
                                  //error al eliminar
                                  toastr.error("Error al elminira la votacion");
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                          }

                        });
                      }
                      $scope.marcarVisto = function ( row ) {
                        var usuarioId = authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
                        var res = $scope.findInArray2(
                          {
                            usuarioId : usuarioId
                          }, row.ttComentarioUsuario
                        );
                        if (res.comentarioUsuarioId != null) {
                          var index = $scope.gridOptionsDetalle.data.indexOf(row);
                          if (res.puntoVisto == 1) {
                            res.puntoVisto = 0;
                          } else {
                            res.puntoVisto = 1;
                          }
                          agendaService.updComentario(res).then( function ( result ) {
                            if(result.status=='OK') {
                              toastr.success(result.message);
                            } else {
                              toastr.error( "Error: " + result.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        } else {
                          var res = {
                            //comentario : ".",
                            comentarioUsuarioId: 0,
                            detalleSesionId : row.detalleSesionId,
                            puntoAprobado : 0,
                            puntoVisto : 1,
                            usuarioId : usuarioId
                          }
                          agendaService.addComentario(res).then( function ( result ) {
                            if(result.status=='OK') {
                              row.ttComentarioUsuario.push(result.data[0]);
                              toastr.success("Punto marcado como visto exitosamente");
                            } else {
                              toastr.error( "Error al marcar como visto el punto: " + result.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }
                      }

                      $scope.getTableHeight = function() {
                        var rowHeight = 80; // your row height
                        var headerHeight = 80; // your header height
                        var rows = $scope.gridOptionsDetalle.data.length;

                        if (rows <= 0) {
                          rows = 1;
                        } else {
                          if (rows > appSettings.paginationPageSize) {
                            rows = appSettings.paginationPageSize;
                          }
                        }
                        return {
                          height: (rows * rowHeight + headerHeight) + "px"
                        };
                      };

                      $scope.abrirTablero = function () {
                        localStorageService.set('detalles',JSON.stringify($scope.sesion.ttDetalleSesions));
                        var url = $state.href('index.tablero.list', { sesionId : $scope.sesion.sesionId });
                        window.open(url, '_blank');
                      }

                      $scope.inicio = {};
                      $scope.inicio.inicializando = 1;
                      //http://stackoverflow.com/questions/29345481/control-or-disable-browser-back-button-with-javascript-or-angularjs#29345506
                      $scope.$on('$locationChangeStart', function(event, next, current){
                        if ($scope.inicio.inicializando == 0) {
                          alert('Lo sentimos, no es una opción válida');
                          event.preventDefault();
                        } else {
                          $scope.inicio.inicializando = 0;
                        }
                      });

                      $scope.detalles.sort(function(a, b) {
                         return parseFloat(a.numeracion) - parseFloat(b.numeracion);
                       });
                      $scope.buildTree($scope.detalles);
                      //$scope.gridOptionsDetalle.data=$scope.detalles;
                    }

                    $scope.mostrarAdjuntos=function (row) //agenda view
                    {
                      if (row.ttArchivoAdjuntos.length > 0) {
                        $scope.sesion.currentDetalle=row.descripcion;
                        $scope.gridOptionsAdjuntos.data=row.ttArchivoAdjuntos;
                        if(row.ttArchivoAdjuntos!=null && row.ttArchivoAdjuntos.length==1)
                        {
                          $scope.verAdjunto(row.ttArchivoAdjuntos[0]);
                          return;
                        }

                        ngDialog.open({
                           template: 'app/agenda/agenda.adjuntos.tpl.html',
                           className: 'ngdialog-theme-flat',
                           closeByDocument: true,
                           closeByEscape: true,
                           width: 400,
                           height: 400,
                           scope: $scope
                         });
                      } else {
                        toastr.warning('No hay archivos adjuntos');
                      }
                    }
                    $scope.cerrarAdjuntos=function()
                    {
                      ngDialog.close();
                    }
                    $scope.findInArray2 = function(condicion, array)
                    {
                        var res;
                         var found = $filter('filter')(array, condicion, true);
                         if (found.length)
                         {
                             res = found[0];
                         }
                         else
                         {
                             res   = 'Not found';
                         }
                         return res;
                     }

                     $scope.limpiarComentario = function () {
                       $scope.sesion.comentario = "";
                       $scope.actualizarComentario();
                     }

                    $scope.addComentario=function (row)
                    {

                      //$scope.gridOptionsAdjuntos.data=row.ttArchivoAdjuntos;
                      $scope.detalle=row;
                      var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                      if(res.length>0)
                      {
                        if (/*res.comentario == "." ||*/ res.comentario == "") {
                          $scope.existComment = false;
                        } else {
                          $scope.existComment=true;
                        }
                      } else {
                        $scope.existComment=false;
                        $scope.sesion.comentarioUsuarioId=res.comentarioUsuarioId;
                      }
                      $scope.sesion.comentario=res.comentario;
                      if (res.estado == 2) {
                        $scope.sesion.bndEstado = true;
                      } else {
                        $scope.sesion.bndEstado = false;
                      }
                      $scope.sesion.detalleSesionId=row.detalleSesionId;
                      $scope.sesion.objcomentario=res;
                      $scope.sesion.comentarios=row.ttComentarioUsuario;
                      ngDialog.open({
                         template: 'app/agenda/agenda.comentario.tpl.html',
                         className: 'ngdialog-theme-flat',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '70%',
                         height:'60%',
                         scope: $scope
                       });
                    }

                    $scope.guardarComentario=function()
                    {
                      if($scope.sesion.comentario==null)
                        toastr.error("Ingrese el texto del comentario.");
                      else
                      {
                        var estado = 0;
                        if ($scope.sesion.bndEstado == true) {
                          estado = 2;
                        } else {
                          estado = 1;
                        }
                        var a={
                          comentario:$scope.sesion.comentario,
                          detalleSesionId:$scope.sesion.detalleSesionId,
                          usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                          puntoVisto : 0,
                          puntoAprobado : 0,
                          estado : estado
                        };
                        agendaService.addComentario(a).then( function ( result )
                        {
                          if(result.status=='OK')
                          {
                            //$scope.sesion.objcomentario.comentario=a.comentario;
                            a.comentarioUsuarioId=result.data[0].comentarioUsuarioId;
                            $scope.sesion.comentarios.push(a);
                            toastr.success("Comentario almacenado con exito");
                            ngDialog.close();
                          }
                          else
                          {
                            toastr.error( "Error al almacenar el comentario:"+result.message );
                          }

                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }

                    }
                    $scope.actualizarComentario=function()
                    {
                      if ($scope.sesion.comentario == null) {
                        //$scope.sesion.comentario = '.';
                      } else {
                        if ($scope.sesion.comentario == "") {
                          //$scope.sesion.comentario = '.';
                        }
                      }
                      var estado = 0;
                      if ($scope.sesion.bndEstado == true) {
                        estado = 2;
                      } else {
                        estado = 1;
                      }
                      var a={
                        comentarioUsuarioId:$scope.sesion.comentarioUsuarioId,
                        comentario:$scope.sesion.comentario,
                        detalleSesionId:$scope.sesion.detalleSesionId,
                        usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId,
                        puntoVisto : $scope.sesion.comentarios[0].puntoVisto,
                        puntoAprobado : $scope.sesion.comentarios[0].puntoAprobado,
                        estado : estado
                      };
                      agendaService.updComentario(a).then( function ( result ) //actualizar comentario view
                      {
                        if(result.status=='OK')
                        {
                          $scope.sesion.objcomentario.comentario=a.comentario;
                          toastr.success("Comentario actualizado con exito");
                          ngDialog.close();
                        }
                        else
                        {
                          toastr.error( "Error al actualizar el comentario:"+result.message );
                        }

                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                    $scope.cerrarComentario=function()
                    {
                      ngDialog.close();
                    }
                    $scope.verAdjunto=function(row)
                    {
                      /*var link = angular.element('<a href="' + appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId + '" target="_blank"></a>');
                      angular.element(document.body).append(link);
                      link[0].click();
                      link.remove();*/
                      //window.open(appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId, '_blank');
                      var urlReporteAgenda;
                      urlReporteAgenda=appSettings.restApiServiceBaseUri+"sesion/file/"+row.archivoAdjuntoId;
                      $scope.sesion.path2=encodeURIComponent(urlReporteAgenda)

                      urlReporteAgenda='./web/viewer.html?file='+$scope.sesion.path2;

                      $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                      $scope.iframe = {src:urlReporteAgenda};
                      ngDialog.open({
                         template: 'app/agenda/agenda.viewdetalle.tpl.html',
                         className: 'ngdialog-theme-default',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '95%',
                         height:'95%',
                         scope: $scope
                       });
                    }

                    $scope.mostrarAyuda=function(row)
                    {

                      var urlReporteAgenda;
                      urlReporteAgenda="./pdf/ayuda.pdf";
                      $scope.sesion.path2=encodeURIComponent(urlReporteAgenda)

                      urlReporteAgenda='./web/viewer.html?file='+$scope.sesion.path2;

                      $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                      $scope.iframe = {src:urlReporteAgenda};
                      ngDialog.open({
                         template: 'app/agenda/agenda.viewdetalle.tpl.html',
                         className: 'ngdialog-theme-flat',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '100%',
                         height:'100%',
                         scope: $scope
                       });
                    }

                    $scope.reporteComentarios=function()
                    {
                      window.open(appSettings.reportingServicesUri + 'Reporte.aspx?p=/AGENDAPLENO/AP_COMENTARIOS_SESION&P_SESION_ID='+$scope.sesion.sesionId+'&P_USUARIO_ID='+authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId+'&P_USUARIO='+authService.getLocalData('loginData'+ appSettings.sistemaId).usuario,'Reporte', 'width=800, height=600' );
                    }

                    $scope.vistaPrevia = function () {
                      //window.open( 'http://reporteria.oj.gob.gt/Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId);
                      var urlReporteAgenda;
                      if (appSettings.profile == 3) {
                        urlReporteAgenda = appSettings.restApiServiceBaseUri + "sesion/reporte/" + $scope.sesion.sesionId;
                      } else {
                        urlReporteAgenda = appSettings.reportingServicesUri +'Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId;//+'&p_base='+ appSettings.restApiServiceBaseUri;
                      }
                      $scope.sesion.path2=encodeURIComponent(urlReporteAgenda)

                      urlReporteAgenda='./web/viewer.html?file='+$scope.sesion.path2;

                      $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                      $scope.iframe = {src:urlReporteAgenda};
                      ngDialog.open({
                         template: 'app/agenda/agenda.viewdetalle.tpl.html',
                         className: 'ngDialog-custom-width',
                         closeByDocument: true,
                         closeByEscape: true,
                         width: '90%',
                         height:'90%',
                         scope: $scope
                       });
                    }

                    $scope.refrescar= function ()
                    {
                      //$state.reload();
                      agendaService.get($scope.sesion.sesionId).then( function ( result ) {
                        if(result.status=='OK') {
                          //$scope.gridOptionsDetalle.data = result.data;
                          $scope.sesion = result.data[0];
                          $scope.detalles=$scope.sesion.ttDetalleSesions;
                        } else {
                          toastr.error( "Error al actualizar: " + result.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }

                    $scope.existeComentarioDetalle= function(row) {
                      var res=$scope.findInArray2({usuarioId:authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId},row.ttComentarioUsuario);
                      if(res.comentario!=null && res.comentario!="") {
                        /*if (res.comentario == ".") {
                          return false;
                        }*/
                        return true;
                      } else{
                        return false;
                      }
                    }

                    $scope.vistaPrevia();
                } //end controller
              ]
            }
          }
        })
        .state( 'index.agenda.viewdetalle', {
          url: '/:id/view',
          views: {
            '': {
              templateUrl: 'app/agenda/agenda.viewdetalle.tpl.html',
              resolve: {
                sesion: ['agendaService', '$stateParams',
                  function( agendaService, $stateParams){
                    $dataSesion = agendaService.get( $stateParams.id);//usuarioId=1
                    return $dataSesion;
                  }],
              },
              controller: ['$scope', '$state', 'toastr', 'agendaService','sesion','$sce','ngDialog','uiGridConstants','$stateParams','$filter','authService',
                function (  $scope,   $state,   toastr,   agendaService,sesion,$sce,ngDialog,uiGridConstants,$stateParams,$filter,authService)
                {
                    //$scope.existComment=false;
                    $scope.setTabs(2,$stateParams.id);//setea los tabs
                    if(sesion==null||sesion.data==null||sesion.data[0]==null)
                    {
                      toastr.error("Error al obtener los datos: " +sesion.message);
                    }
                    else
                    {
                      sesion.data[0].sesion=sesion.data[0].anio+"-"+sesion.data[0].correlativo;
                      sesion.data[0].tipo=sesion.data[0].tipoSesion==1?"Ordinaria":"Extraordinaria"

                      $scope.sesion=sesion.data[0]

                      var urlReporteAgenda;
                      urlReporteAgenda=appSettings.reportingServicesUri + 'Reporte.aspx?p=/AGENDAPLENO/AP_AGENDA_SESION&P_SESION_ID='+$scope.sesion.sesionId;//+'&p_base='+ appSettings.restApiServiceBaseUri;
                      $scope.sesion.path2=encodeURIComponent(urlReporteAgenda)

                      urlReporteAgenda='http://agendapleno.oj.gob.gt/web/viewer.html?file='+$scope.sesion.path2;

                      $scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                      $scope.iframe = {src:urlReporteAgenda};

                      /**************anterior ab*************/
                    }

                } //end controller
              ]
            }
          }
        })
    }
  ]
);
