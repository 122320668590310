/////////////////////////////
// Parametros del sistema //
/////////////////////////////
var appSettings = {
  isDevelopment: true,

  restApiServiceBaseUri: 'http://serviciosrest.oj.gob.gt:8080/agendaplenorst/', // REST dmz
  //restApiServiceBaseUri: 'http://wsdesacit.oj.gob.gt:8080/agendaplenaria/', // REST desarrollo
  //restApiServiceBaseUri: 'http://agendaservice.oj.gob.gt:8080/agendaplenaria/', // REST producción interno
  //restApiServiceBaseUri: 'http://localhost:8080/agendaplenaria/', // REST local
  //restActiveDirectory : 'http://servicioscit.oj.gob.gt:8080/oauth/',// oauth prod
  restActiveDirectory : 'http://serviciosrest.oj.gob.gt:8080/agendaplenorst/oauth/',// oauth dmz
  //restActiveDirectory : 'http://wsdesacit.oj.gob.gt:8080/oauth/', //oauth desa
  reportingServicesUri: 'http://reporteria.oj.gob.gt/',
  //reportingServicesUri: 'http://reporteriadesa.oj.gob.gt/',

  timeOuttoastrNotifications: '15000',
  paginationPageSizes: [20, 25, 50],
  paginationMinPageSizes: [15, 25, 25],
  paginationPageSize: 5,
  smartTable: { itemsByPage: 5, displayedPages: 5 },
  appVersion: '0.09',
  sistemaId : 6,
  perfilId : -1,
  profile : 3 //1 = production, 2 = develop, 3 = DMZ
};

/////////////////////////////
// Inicializacion de modulo//
// principal del sistema   //
/////////////////////////////
angular.module( 'app', [
  'templates-app',
  'ngSanitize',
  'ui.bootstrap',
  'ui.grid',
  'ui.grid.pagination',
  'ui.grid.selection',
  'ui.grid.edit',
  'ui.grid.autoResize',
  'ui.grid.grouping',
  'ui.grid.treeView',
  'ui.router',
  'ui.select',
  'ui.calendar',
  'LocalStorageModule',
  'chieffancypants.loadingBar',
  'toastr',
  'ngDialog',
  'ui.mask',
  'ui.bootstrap.datetimepicker',
  'app.forbidden',
  'base64',
  'ngFileUpload',
  //'chart.js',

  'app.generico',
  'app.utilsService',
  'app.authService',
  'app.authInterceptorService',
  'app.directives',
  'app.login',
  'app.agenda',
  //'app.tablero',
  'app.usuario',
  'mwl.calendar'
])

.filter( 'dateFilter', ['$filter', function ($filter) {
  return function (input) {
    if (!input) {
      return '';
    } else {
      return $filter( 'date' )(new Date(input),'dd/MM/yyyy' );
    }
  };
}])

.filter( 'propsFilter', [function () {
  return function (items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach( function (item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
}])

.run(
  [          '$rootScope','$window', '$state', '$stateParams', 'appSettings', 'toastr', 'ngDialog',
    function ($rootScope, $window,  $state,   $stateParams,   appSettings,   toastr,   ngDialog) {

      // add references to $state and $stateParams to the $rootScope
      // For example <li ng-class="{ active: $state.includes( 'contacts.list' ) }"> will set the <li>
      // to active whenever 'contacts.list' or one of its decendents is active.
      /*Funciones para Prevenir cerrar sin guardar*/
      $rootScope.existChanges=false;
       $rootScope.onExit = function() {
         return ('bye bye');
       };

       $rootScope.setPreventExit=function(on)
       {
         if(on)
           $window.onbeforeunload =  $rootScope.onExit;
         else
           $window.onbeforeunload = undefined;
       }

       $rootScope.$watchCollection('existChanges', function(newVal,oldVal)
       {
          if($rootScope.existChanges)
          {
            $rootScope.setPreventExit(true);
            //toastr.success("Cambio en existChanges");
          }
          else
          {
            //toastr.error("No hay cambios en existChanges");
            $rootScope.setPreventExit(false);
          }
        },true);
      $rootScope.setChangeListener=function(scope,objeto)
      {
        scope.$watchCollection(objeto, function(newVal,oldVal)
        {
          if(newVal==oldVal)
           {
             //toastr.error("no hay cambio en "+objeto);
           }
           else
           {
             //toastr.success("Cambio en " + objeto);
             $rootScope.existChanges=true;
           }

         },true);
      }
      $rootScope.setExistChanges=function(existChanges)
      {
        $rootScope.existChanges=existChanges;
      }

       /******/
      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;

      $rootScope.$on( '$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        // prevenir accion por default
        event.preventDefault();
        // cerrar todos los dialogos abiertos si hay
        ngDialog.close();

        if ( error && error.status == 401 ) {
          $state.go( 'login' );
        } else {
          if ( error && error.status == 403 ) {
            $state.go( 'index.forbidden.show' );
          } else {
            location.href = '#/';
          }
        }
      });
      $rootScope.$on('$stateChangeStart',
       function(event, toState, toParams, fromState, fromParams, options){
         if($rootScope.existChanges)
         {
           event.preventDefault();
           swal({
             title: "¿Existen cambios sin guardar, está seguro de salir y perder los cambios??",
             text: "",
             showCancelButton: true,
             confirmButtonClass: "btn btn-success",
             confirmButtonText: "Confirmar",
             cancelButtonClass: "btn btn-danger",
             cancelButtonText: "Cancelar",
             closeOnConfirm: true,
           },
           function (isValid) {
             if ( isValid ) {
               $rootScope.existChanges=false;
               $state.go(toState,toParams);
             }
             else {

             }

           });

         }

           // transitionTo() promise will be rejected with
           // a 'transition prevented' error

           //console.log(event + '___' + toState  + '___' + toParams  + '___' + fromState  + '___' + fromParams  + '___' + options);
       });
    }
  ]
)

.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.interceptors.push( 'authInterceptorService' );
}])

.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
  cfpLoadingBarProvider.spinnerTemplate = '<div class="spinner"> <div id="loading-bar-spinner"><div class="preloader pl-lg"> <svg class="pl-circular" viewBox="25 25 50 50"><circle class="plc-path" cx="50" cy="50" r="20"></circle></svg> </div></div> </div>';
}])

.config(['ngDialogProvider', function (ngDialogProvider) {
  ngDialogProvider.setDefaults({
    className: 'ngdialog-theme-flat',
    showClose: true,
    closeByDocument: true,
    closeByEscape: true,
    cache: true,
    overlay: true
  });
}])

.config(['toastrConfig', function (toastrConfig) {
  angular.extend(toastrConfig, {
    autoDismiss: false,
    containerId: 'toast-container',
    maxOpened: 0,
    newestOnTop: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    preventOpenDuplicates: false,
    target: 'body'
  });
}])

/* .config( function (calendarConfig) {
  calendarConfig.showTimesOnWeekView = true; //Make the week view more like the day view, with the caveat that event end times are ignored.
})*/

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {

      var authenticated = ['$location', '$q', 'authService', function ($location, $q, authService) {
        var deferred = $q.defer();
        if ( authService.isLoggedIn() ) {
          deferred.resolve();
        } else {
          deferred.reject( { status: 401 } );
        }
        return deferred.promise;
      }];

      /////////////////////////////
      // Redirects and Otherwise //
      /////////////////////////////

      $urlRouterProvider

        // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
        .otherwise( '/' );


      //////////////////////////
      // State Configurations //
      //////////////////////////

      // Use $stateProvider to configure your states.
      $stateProvider

        //////////
        // Home //
        //////////

        .state("index", {

          abstract: true,

          url: "/",

          templateUrl: 'app/index.tpl.html',

          resolve: {
            // descomentar para que tenga seguridad la ruta
            authenticated: authenticated,
            dataParametro: ['agendaService',
              function( agendaService){
                return agendaService.getParametro( 1 );
              }]
          },
          controller: ['$scope', 'toastr', 'appSettings', 'utilsService', 'authService', '$state', '$window',
            'localStorageService','dataParametro',
            function ($scope, toastr, appSettings, utilsService, authService, $state, $window,
            localStorageService,dataParametro) {
              $scope.dataParametro = dataParametro.data[0];
              $scope.loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
              if (localStorageService.get( 'appVersion' + appSettings.sistemaId )){ // verifica la version y se recarga en caso no esta con la version actual del sistema
                if (localStorageService.get( 'appVersion' + appSettings.sistemaId ) != appSettings.appVersion){
                  localStorageService.set( 'appVersion' + appSettings.sistemaId, appSettings.appVersion);
                  //authService.logOut();
                  window.location.reload();
                }
              } else{
                localStorageService.set( 'appVersion' + appSettings.sistemaId, appSettings.appVersion);
                //authService.logOut();
                window.location.reload();
              }
              //$scope.sesion={};
              // inicializar la plantilla AdminLTE
              _initAdminLTETemplate();
              /*Create menu*/
              var dataMenu = authService.getLocalData( 'menu' + appSettings.sistemaId );
              $scope.getNombreMenu = function ( id ) {
                var strNombre = 'Menu ' + id;
                for (var i = 0; i < dataMenu.length; i++) {
                  if (id == dataMenu[i].codigoPantalla) {
                    strNombre = dataMenu[i].menuNombre;
                    break;
                  }
                }
                return strNombre;
              }

              $scope.getDataMenu = function ( id ) {
                var hijos = [];
                for (var i = 0; i < dataMenu.length; i++) {
                  if (id == dataMenu[i].codigoPantalla) {
                    hijos = dataMenu[i].hijos;
                    break;
                  }
                }
                return hijos;
              }
              // dateOptions
              $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 0,
                format: 'dd/MM/yyyy',
                formatDateTime: 'dd/MM/yyyy HH:mm',
                showMeridian: false
              };

              $scope.colorDia = function ( dia ) {
                return 'success';
              }

              $scope.showDate = function(value)
              {
                return new Date(value);
              }

              // objeto fechas que contiene todas las fechas de los forms
              $scope.openedDates = {};

              // funcion que muestra el datepicker
              $scope.openDate = function($event, field) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedDates[field] = true;
              };

              $scope.smartTable = appSettings.smartTable;

              // grid
              $scope.gridOptions = {
                enableRowSelection: true,
                multiSelect: true,
                enableSelectAll: true,
                enableRowHeaderSelection: false,
                selectionRowHeaderWidth: 35,
                groupingRowHeaderWidth: 50,
                rowHeight: 80,
                paginationPageSizes: appSettings.paginationPageSizes,
                paginationPageSize: appSettings.paginationPageSize,
                data: []
              };

              $scope.gridOptionsSelection = {
                enableRowSelection: true,
                multiSelect:false,
                enableSelectAll: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: appSettings.paginationPageSizes,
                paginationPageSize: appSettings.paginationPageSize,
                data: []
              };


              $scope.goTab = function(event) {
                event.preventDefault();
              };

              $scope.typeof = function(value, type) {
                return typeof value === type
              };

              /*$scope.$on('$locationChangeStart', function(event, next, current){
                // Here you can take the control and call your own functions:
                //alert('Sorry ! Back Button is disabled');
                // Prevent the browser default action (Going back):
                event.preventDefault();
              });*/

              $scope.logout = function(isValid) {
                $state.go('logout');
              };
              $scope.tituloMenu = 'Bienvenidas (os)';
              $scope.current=
              {
                sesion:
                {
                  anio:0,
                  correlativo:0,
                  descripcion:null,
                  estado:0,
                  fechaHora:null,
                  sesionId:0,
                  tipoSesion:0,
                  ttDetalleSesions : [], //ttArchivoAdjuntos:[],
                  urlEncabezado:null
                }
              }
              $scope.current.limpiar=function()
              {
                $scope.current.sesion=
                {
                  anio:0,
                  correlativo:0,
                  descripcion:null,
                  estado:0,
                  fechaHora:null,
                  sesionId:0,
                  tipoSesion:0,
                  ttDetalleSesions : [],//ttArchivoAdjuntos:[],
                  urlEncabezado:null
                };
              };
              var dataPermiso = authService.getLocalData( 'permiso' + appSettings.sistemaId );
              appSettings.perfilId=dataPermiso[0].perfilId;
              console.log($state.current.name);
              if($state.current.name!='index.tablero.list')
              {
                if (dataPermiso[0].perfilId == 7) {
                  $state.go( 'index.agenda.list' );
                }
                if (dataPermiso[0].perfilId == 5) {
                  $state.go( 'index.agenda.listadmin' );
                }
              }
            }]
        })
        .state("index.home", {

          url: "",

          views: {
            '': {
              templateUrl: 'app/home/home.tpl.html',
              resolve: {

              },
              controller: ['$scope', 'toastr', '$state',
                function ($scope, toastr, $state) {

                }
              ]
            },
            'hint@index': {
              template: '<h1>Bienvenid@s</h1>'
            }
          }

        })

    }
  ]
)

.constant( 'appSettings', appSettings);
