var myApp=angular.module('app.usuario', [
  'ui.router',
  'app.usuarioService',
  'app.agendaService',
  'toastr',
  'app.authService'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.usuario', {
          abstract: true,
          url: 'usuario',
          params: {
          },
          views: {
            '': {
              templateUrl: 'app/usuario/usuario.tpl.html',
              resolve: {
              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {
                }]
            }
          }

        })
        .state( 'index.usuario.list', {

          url: '/list',

          views: {
            '': {
              templateUrl: 'app/usuario/usuario.list.tpl.html',
              resolve: {
                usuarios: ['usuarioService','authService',
                  function( usuarioService,authService){
                    return usuarioService.list();
                  }],
              },
              controller: ['$scope', '$state', 'toastr','usuarioService','usuarios','authService','uiGridConstants','agendaService','$filter',
                function (  $scope,   $state,   toastr,usuarioService,usuarios,authService,uiGridConstants,agendaService,$filter) {
                  //todo el codigo
                  $scope.gridOptionsUsuario = angular.copy( $scope.gridOptions );
                  $scope.gridOptionsUsuario.columnDefs = [
                    { name: 'usuarioId', displayName: "ID", width: "5%",  sort: { direction: uiGridConstants.ASC }, enableCellEdit: false},
                    { name: 'usuario', displayName: "Usuario" , enableCellEdit: false},
                    { name: 'usuarioNombre', displayName: "Nombre", enableCellEdit: false },
                    //{ name: 'estado', displayName: "Estado", width: "15%", },
                    { name: 'estado', displayName: "Estado", enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>{{row.entity.estado==1 ? "Activo" : "No Activo"}}</span></div>',width: "10%", enableCellEdit: false},
                    { name: 'correo', displayName: "email", enableCellEdit: false},
                    { name: 'fechaRegistro', displayName: "Fecha Registro",cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' ,enableCellEdit: false},
                    //{ name: 'usuarioIng', displayName: "Usuario Crea" , enableCellEdit: false},
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.edit(row.entity)" title="Editar"><i class="fa fa-edit"></i></button></span></div>', width: "8%" ,enableCellEdit: false},
                  ];
                  $scope.findInArray = function(condicion, array)
                  {
                      var res;
                       var found = $filter('filter')(array, condicion, true);
                       if (found.length)
                       {
                           res = found[0];
                       }

                       return res;
                   }
                  $scope.cargarContactos=function()
                  {
                    var contacto={
                      usuario:""
                    }
                    var stringUsuarios="";
                    if(usuarios.data.length>0)
                    {
                      for(usuario in usuarios.data)
                      {
                        stringUsuarios+=usuarios.data[usuario].usuarioId+",";
                      }
                      stringUsuarios=stringUsuarios.slice(0, -1);
                      contacto.usuario=stringUsuarios;
                      agendaService.datosParticipantes(contacto).then( function ( response ) {
                        if(response.status=='OK')
                        {
                          for(contacto in response.data)
                          {
                            response.data[contacto].contactoId=$scope.findInArray({usuarioId:response.data[contacto].usuarioId},usuarios.data).contactoId;
                            response.data[contacto].estado=$scope.findInArray({usuarioId:response.data[contacto].usuarioId},usuarios.data).estado;
                          }
                            $scope.gridOptionsUsuario.data=response.data;
                        }
                        else {
                          toastr.error( response.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }

                  }
                  $scope.cargarContactos();
                  $scope.edit = function( row ) {
                      $state.go("index.usuario.edit", { id: row.contactoId })
                  }
                  $scope.show = function( row ) {
                      $state.go("index.usuario.show",
                      {
                        id:row.usuarioId
                      }
                    );
                  }

                }]
            }
          }
        })
        .state( 'index.usuario.add', {

          url: '/add',

          views: {
            '': {
              templateUrl: 'app/usuario/usuario.add.tpl.html',
              resolve: {

              },
              controller: ['$scope', '$state', 'toastr','usuarioService','authService',
                function (  $scope,   $state,   toastr,usuarioService,authService) {
                  //todo el codigo
                  $scope.users=[];//usuarios del sistema desde esquema seguridad
                  $scope.usuario={};

                  $scope.gridOptionsUser = angular.copy( $scope.gridOptions );
                  $scope.gridOptionsUser.enableFiltering= true;
                  $scope.gridOptionsUser.columnDefs = [
                    { name: 'usuarioId', displayName: "ID", width: "5%" },
                    { name: 'codigoEmpleado', displayName: "Empleado", width: "10%" },
                    { name: 'usuarioNombre', displayName: "Nombre",  },
                    { name: 'usuario', displayName: "usuario" },
                    { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn bg-light-blue-active btn-xs" ng-click="grid.appScope.select(row.entity)" title="Seleccionar"><i class="fa fa-arrow-circle-left"></i></button></span></div>',width: "8%"},
                  ];






                  usuarioService.users().then( function ( response ) {
                    $scope.users=response.data;
                    $scope.gridOptionsUser.data=$scope.users;

                  }, function ( error ) {
                    toastr.error( error );
                  });
                  $scope.select=function(row){
                    $scope.usuario=row;
                  };
                  $scope.submitForm = function ( isValid ) {
                    if ( isValid ) {
                      $scope.usuario.nombre=$scope.usuario.usuarioNombre;
                      $scope.usuario.usuarioIng=$scope.loginData.usuarioId;
                      usuarioService.add( $scope.usuario ).then( function ( response ) {
                        if(response.status=="OK")
                        {
                          toastr.success( response.message );
                          $state.go("index.usuario.list")
                        }
                        else {
                            toastr.error( response.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  };

                }]//fin controller
            }
          }
        })
        .state( 'index.usuario.show', {

          url: '/:id/show',

          views: {
            '': {
              templateUrl: 'app/usuario/usuario.show.tpl.html',
              resolve: {

                  usuario: ['usuarioService', '$stateParams',
                  function( usuarioService, $stateParams){
                    $dataUsuario = usuarioService.get( $stateParams.id, 1);//usuarioId=1
                    return $dataUsuario;
                  }],

              },
              controller: ['$scope', '$state', 'toastr', 'ngDialog','usuario',
                function ($scope, $state, toastr, ngDialog, usuario) {

                //$scope.usuario2=$scope.usuario;
                //$scope.usuario=usuario;
                $scope.restUri=appSettings.restApiServiceBaseUri;
                $scope.mensajeDisabled=true;
                $scope.adjuntos=
                [
                  {
                    nombreArchivo:"archivo1.pdf"
                  },
                  {
                    nombreArchivo:"archivo2.pdf"
                  }
                ];
                $scope.usuario=usuario.data[0];/*[
                  {
                    asunto:'asuntosdfa',
                    mensaje:'mensajeadsfsa'
                  }
                ];*/

                } //end function
              ] //end controller
            }
          }
        })
        .state( 'index.usuario.edit', {

          url: '/:id/edit',

          views: {
            '': {
              templateUrl: 'app/usuario/usuario.edit.tpl.html',
              resolve: {
                usuario: ['usuarioService','$stateParams',
                    function( usuarioService,$stateParams){
                      return usuarioService.get($stateParams.id);
                    }]
              },
              controller: ['$scope', '$state', 'toastr','usuario','usuarioService','agendaService',
                function (  $scope,   $state,   toastr, usuario,usuarioService,agendaService) {
                  $scope.estados=[
                    {
                      estadoId:0,
                      nombre:"No Activo"
                    },
                    {
                      estadoId:1,
                      nombre:"Activo"
                    }
                  ]

                  if(usuario.status=="OK" )
                  {
                      //$scope.usuario=usuario.data[0];
                      agendaService.getDatosContacto(usuario.data[0].usuarioId).then( function ( response ) {
                        if(response.status=='OK')
                        {
                          var contactoId=usuario.data[0].contactoId;
                            $scope.usuario=response.data[0];
                            $scope.usuario.contactoId=contactoId;
                        }
                        else {
                          toastr.error( error );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                  }
                  else {
                    toastr.error( usuario.message );
                  }

                  $scope.submitForm = function ( isValid ) {
                    if ( isValid ) {
                      usuarioService.update( $scope.usuario ).then( function ( response ) {
                        if(response.status=="OK")
                        {
                          toastr.success( response.message );
                          $state.go("index.usuario.list");
                        }
                        else {
                          toastr.error(response.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  }

                }]
            }
          }
        })

    }
  ]
);
myApp.directive('fileModel', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;

            element.bind('change', function(){
                scope.$apply(function(){
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);
