angular.module('app.agendaService', [

])
.factory('agendaService', ['$http', '$q', 'appSettings','authService','$sce', function ( $http, $q, appSettings,authService,$sce ) {

  return {
      list: function( estado) {
        var deferred = $q.defer();
        var estadoId=(estado==undefined)?'':estado;
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/listado/'+estadoId ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      listaParticipantes: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'contacto/lista/1').success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      historico: function( estado) {
        var deferred = $q.defer();

        $http.get( appSettings.restApiServiceBaseUri + 'sesion/historico/'+estado ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      add: function( data ) {
        var deferred = $q.defer();
        data.usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.post( appSettings.restApiServiceBaseUri + 'sesion/agregar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      get: function( id ) {
        var deferred = $q.defer();
        var usuarioId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/' + id +'/'+usuarioId).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getParametro: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/parametro/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getDetalle: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/' + id + '/detalle').success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getVotoDetalle: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'voto/detalle/' + id + '/lista').success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getDatosContacto: function( id ) {
        var deferred = $q.defer();
        $http.get( appSettings.restActiveDirectory + 'usuario/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      update: function( data ) {
        var deferred = $q.defer();
        data.usuarioModificaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.put( appSettings.restApiServiceBaseUri + 'sesion/' + data.sesionId, data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      updComentario: function( data ) {
        var deferred = $q.defer();
        $http.put( appSettings.restApiServiceBaseUri + 'comentario/' + data.comentarioUsuarioId, data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      delete: function( id ) {
        var deferred = $q.defer();
        //data.usuarioModificaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.delete( appSettings.restApiServiceBaseUri + 'sesion/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      tiposVoto: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'voto/tipovoto/lista' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getDetalleSesionId: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/detalleSesion/secuencia' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      sigCorrelativo: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'sesion/sigCorrelativo' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getSalas: function(id) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'ubicacion/'+id+'/detalleubicacion' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      tipos: function() {
        var deferred = $q.defer();
        var result={};
        var data=
        [
          {
            tipoId:1,
            tipoDesc:"Ordinaria"
          },
          {
            tipoId:2,
            tipoDesc:"Extraordinaria"

          }
        ];
        result.data=data;
        deferred.resolve(result);
        return deferred.promise;

        $http.get( appSettings.restApiServiceBaseUri + 'agenda/tipos' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });

        return deferred.promise;
      },
      addComentario: function( data ) {
        var deferred = $q.defer();
        $http.post( appSettings.restApiServiceBaseUri + 'comentario/agregar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      datosParticipantes:function( data ) {
        var deferred = $q.defer();
        $http.post( appSettings.restActiveDirectory + 'usuario/informacion/lista', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      getPdf: function(url){
        var deferred = $q.defer();
            //var url2=$sce.trustAsResourceUrl(url);
            $http.get(url, {responseType: 'arraybuffer'})
           .success(function (data) {
               var file = new Blob([data], {type: 'application/pdf'});
               var fileURL = URL.createObjectURL(file);
               deferred.resolve( fileURL );
             }).error( function ( error ) {
               deferred.reject( error );
             });
        return deferred.promise;
      },
      finalizarSesion:function( data ) {
        var deferred = $q.defer();
        data.usuarioModificaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        console.log(data)
        $http.post( appSettings.restApiServiceBaseUri + 'sesion/enviarMensaje', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      uploadFile:function(file) {
        console.log(file);
        //var file = ... // get from file input;
        //var backendUrl = ...
        var fd = new FormData();

        fd.append('file', file, file.name);

        var deferred = $q.defer();
        $http.post( appSettings.restApiServiceBaseUri + 'sesion/upload', fd,{
            // this cancels AngularJS normal serialization of request
            transformRequest: angular.identity,
            // this lets browser set `Content-Type: multipart/form-data`
            // header and proper data boundary
            headers: {'Content-Type': undefined}
        } ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      addVoto:function(data)
      {
        var deferred = $q.defer();
        //data.usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.post( appSettings.restApiServiceBaseUri + 'voto/agregar', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      updVoto:function(id,data)
      {
        var deferred = $q.defer();
        //data.usuarioCreaId=authService.getLocalData('loginData'+ appSettings.sistemaId).usuarioId;
        $http.put( appSettings.restApiServiceBaseUri + 'voto/'+id, data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      validaPin:function(data)
      {
        var deferred = $q.defer();
        $http.post( 'http://servicioscit.oj.gob.gt:8080/rrhh/catalogo/validapin', data ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
      deleteVoto: function( id ) {
        var deferred = $q.defer();

        $http.delete( appSettings.restApiServiceBaseUri + 'voto/' + id ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
  }

}]);
